import {
  GET_ALL_OPPS_SUCCESS,
  GET_ALL_OPPS_FAILED,
  GET_ONE_OPP_SUCCESS,
  GET_ONE_OPP_FAILED,
  CREATE_OPP_SUCCESS,
  CREATE_OPP_FAILED,
  UPDATE_OPP_SUCCESS,
  UPDATE_OPP_FAILED,
  GET_APPLICATIONS_FOR_OPP_SUCCESS,
  GET_APPLICATIONS_FOR_OPP_FAILED,
  RESET_OPPORTUNITIES_DOCS,
  CLEAR_OPPORTUNITIES
} from '../actions/actions';
import * as actionTypes from '../types/actionTypes';

const initialState = {
  opportunities: null,
  opportunitiesDocs:[],
  opportunity: null,
  error: null,
  loading: false,
};

const opportunitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_OPPS_SUCCESS:
    return {
      ...state,
      opportunities:action.payload,
      opportunitiesDocs:state.opportunitiesDocs.concat(action.payload.docs),
      loading: false,
      error: null,
    };
    case GET_ALL_OPPS_FAILED:
      return {
        ...state,
        opportunities: null,
        loading: false,
        error: action.payload,
      };
    case GET_ONE_OPP_SUCCESS:
      return {
        ...state,
        opportunity: action.payload,
        loading: false,
        error: null,
      };
    case GET_ONE_OPP_FAILED:
      return {
        ...state,
        opportunity: null,
        loading: false,
        error: action.payload,
      };
    case CREATE_OPP_SUCCESS:
      return { ...state, ...action.payload };
    case CREATE_OPP_FAILED:
      return { ...state, ...action.payload };
    case UPDATE_OPP_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_OPP_FAILED:
      return { ...state, ...action.payload };
    case GET_APPLICATIONS_FOR_OPP_SUCCESS:
      return { ...state, ...action.payload };
    case GET_APPLICATIONS_FOR_OPP_FAILED:
      return { ...state, ...action.payload };
    case RESET_OPPORTUNITIES_DOCS:
      return {
        ...state,
        opportunitiesDocs:[]
      }
    case CLEAR_OPPORTUNITIES:
      return initialState;
    default:
      return state;
  }
};

export default opportunitiesReducer;
