import {
  GET_ALL_CAUSES_SUCCESS,
  GET_ALL_CAUSES_FAILED,
  GET_ONE_CAUSE_SUCCESS,
  GET_ONE_CAUSE_FAILED,
  UPDATE_CAUSE_SUCCESS,
  UPDATE_CAUSE_FAILED,
  UPDATE_CAUSE_TRANSLATION_SUCCESS,
  UPDATE_CAUSE_TRANSLATION_FAILED,
} from '../actions/actions';

const initialState = {
  causes: null,
  cause: null,
  error: null,
};

const causesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CAUSES_SUCCESS:
      return { ...state, causes: action.payload };
    case GET_ALL_CAUSES_FAILED:
      return { ...state, causes: null, error: action.payload };
    case GET_ONE_CAUSE_SUCCESS:
      return { ...state, cause: action.payload, error: null };
    case GET_ONE_CAUSE_FAILED:
      return { ...state, cause: null, error: action.payload };
    case UPDATE_CAUSE_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_CAUSE_FAILED:
      return { ...state, ...action.payload };
    case UPDATE_CAUSE_TRANSLATION_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_CAUSE_TRANSLATION_FAILED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default causesReducer;
