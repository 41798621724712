import APIClient from '../../services/ApiService';

export const GET_ALL_SKILLS_SUCCESS = 'GET_ALL_SKILLS_SUCCESS';
export const GET_ALL_SKILLS_FAILED = 'GET_ALL_SKILLS_FAILED';
export const GET_ONE_SKILL_SUCCESS = 'GET_ONE_SKILL_SUCCESS';
export const GET_ONE_SKILL_FAILED = 'GET_ONE_SKILL_FAILED';
export const UPDATE_SKILL_SUCCESS = 'UPDATE_SKILL_SUCCESS';
export const UPDATE_SKILL_FAILED = 'UPDATE_SKILL_FAILED';
export const UPDATE_SKILL_TRANSLATION_SUCCESS = 'UPDATE_SKILL_TRANSLATION_SUCCESS';
export const UPDATE_SKILL_TRANSLATION_FAILED = 'UPDATE_SKILL_TRANSLATION_FAILED';

function getAllSkillsSuccess(payload) {
  return { type: GET_ALL_SKILLS_SUCCESS, payload };
}

function getAllSkillsFailed(payload) {
  return { type: GET_ALL_SKILLS_FAILED, payload };
}

function getOneSkillSuccess(payload) {
  return { type: GET_ONE_SKILL_SUCCESS, payload };
}

function getOneSkillFailed(payload) {
  return { type: GET_ONE_SKILL_FAILED, payload };
}

function updateSkillSuccess(payload) {
  return { type: UPDATE_SKILL_SUCCESS, payload };
}

function updateSkillFailed(payload) {
  return { type: UPDATE_SKILL_FAILED, payload };
}

function updateSkillTranslationSuccess(payload) {
  return { type: UPDATE_SKILL_TRANSLATION_SUCCESS, payload };
}

function updateSkillTranslationFailed(payload) {
  return { type: UPDATE_SKILL_TRANSLATION_FAILED, payload };
}

export function getAllSkills(payload) {
  return (dispatch) => {
    const { page, limit, pagination } = payload;
    const query = { page, limit, pagination };
    return APIClient.get(`/skills`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { skills } = result.data.data;
          dispatch(getAllSkillsSuccess({ skills, success: true }));
        } else {
          dispatch(
            getAllSkillsFailed({
              skills: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getAllSkillsFailed({
            skills: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function getOneSkill(payload) {
  return (dispatch) => {
    const { skillId } = payload;
    return APIClient.get(`/skills/${skillId}`)
      .then((result) => {
        if (result.data && result.data.success) {
          const { skill } = result.data.data;
          dispatch(getOneSkillSuccess({ skill, success: true }));
        } else {
          dispatch(
            getOneSkillFailed({
              skill: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getOneSkillFailed({
            skill: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearSkill() {
  return updateSkillSuccess({ skill: null, success: false });
}

export function updateSkill(payload) {
  return (dispatch) => {
    return APIClient.put(`/skills/${payload.skillId}`, {
      mainName: payload.mainName,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { skill } = result.data.data;
          dispatch(updateSkillSuccess({ skill, success: true }));
        } else {
          dispatch(
            updateSkillFailed({
              skill: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateSkillFailed({
            skill: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function updateSkillTranslation(payload) {
  return (dispatch) => {
    return APIClient.put(`/skills/${payload.skillId}/translation`, {
      language: payload.language,
      name: payload.name,
      description: payload.description,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { skill } = result.data.data;
          dispatch(updateSkillTranslationSuccess({ skill, success: true }));
        } else {
          dispatch(
            updateSkillTranslationFailed({
              skill: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateSkillTranslationFailed({
            skill: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}
