import * as actionTypes from "../types/actionTypes";

const initialState = { users: null, error: null, loading: false };

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    // @desc    Get all MUNICIPALITIES.
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        users: action.responseData,
      };

    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return null;
  }
};

export default usersReducer;
