import APIClient from '../../services/ApiService';

export const GET_ALL_CAUSES_SUCCESS = 'GET_ALL_CAUSES_SUCCESS';
export const GET_ALL_CAUSES_FAILED = 'GET_ALL_CAUSES_FAILED';
export const GET_ONE_CAUSE_SUCCESS = 'GET_ONE_CAUSE_SUCCESS';
export const GET_ONE_CAUSE_FAILED = 'GET_ONE_CAUSE_FAILED';
export const UPDATE_CAUSE_SUCCESS = 'UPDATE_CAUSE_SUCCESS';
export const UPDATE_CAUSE_FAILED = 'UPDATE_CAUSE_FAILED';
export const UPDATE_CAUSE_TRANSLATION_SUCCESS = 'UPDATE_CAUSE_TRANSLATION_SUCCESS';
export const UPDATE_CAUSE_TRANSLATION_FAILED = 'UPDATE_CAUSE_TRANSLATION_FAILED';

function getAllCausesSuccess(payload) {
  return { type: GET_ALL_CAUSES_SUCCESS, payload };
}

function getAllCausesFailed(payload) {
  return { type: GET_ALL_CAUSES_FAILED, payload };
}

function getOneCauseSuccess(payload) {
  return { type: GET_ONE_CAUSE_SUCCESS, payload };
}

function getOneCauseFailed(payload) {
  return { type: GET_ONE_CAUSE_FAILED, payload };
}

function updateCauseSuccess(payload) {
  return { type: UPDATE_CAUSE_SUCCESS, payload };
}

function updateCauseFailed(payload) {
  return { type: UPDATE_CAUSE_FAILED, payload };
}

function updateCauseTranslationSuccess(payload) {
  return { type: UPDATE_CAUSE_TRANSLATION_SUCCESS, payload };
}

function updateCauseTranslationFailed(payload) {
  return { type: UPDATE_CAUSE_TRANSLATION_FAILED, payload };
}

export function getAllCauses(payload) {
  return (dispatch) => {
    const { page, limit, pagination } = payload;
    const query = { page, limit, pagination };
    return APIClient.get(`/causes`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { causes } = result.data.data;
          dispatch(getAllCausesSuccess({ causes, success: true }));
        } else {
          dispatch(getAllCausesFailed({ causes: null, success: false, error: 'Server Error!' }));
        }
      })
      .catch((error) => {
        dispatch(
          getAllCausesFailed({
            causes: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function getOneCause(payload) {
  return (dispatch) => {
    const { causeId } = payload;
    return APIClient.get(`/causes/${causeId}`)
      .then((result) => {
        if (result.data && result.data.success) {
          const { cause } = result.data.data;
          dispatch(getOneCauseSuccess({ cause, success: true }));
        } else {
          dispatch(getOneCauseFailed({ cause: null, success: false, error: 'Server Error!' }));
        }
      })
      .catch((error) => {
        dispatch(
          getOneCauseFailed({
            cause: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function updateCause(payload) {
  return (dispatch) => {
    return APIClient.put(`/causes/${payload.causeId}`, {
      mainName: payload.mainName,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { cause } = result.data.data;
          dispatch(updateCauseSuccess({ cause, success: true }));
        } else {
          dispatch(updateCauseFailed({ cause: null, success: false, error: 'Server Error!' }));
        }
      })
      .catch((error) => {
        dispatch(
          updateCauseFailed({
            cause: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearCause() {
  return updateCauseTranslationSuccess({ cause: null, success: false });
}

export function updateCauseTranslation(payload) {
  return (dispatch) => {
    return APIClient.put(`/causes/${payload.causeId}/translation`, {
      language: payload.language,
      name: payload.name,
      description: payload.description,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { cause } = result.data.data;
          dispatch(updateCauseTranslationSuccess({ cause, success: true }));
        } else {
          dispatch(
            updateCauseTranslationFailed({ cause: null, success: false, error: 'Server Error!' })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateCauseTranslationFailed({
            cause: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}
