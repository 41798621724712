import APIClient from '../../services/ApiService';
import * as actionTypes from '../types/actionTypes';
import jwt from 'jsonwebtoken';

import swal from 'sweetalert';
// ? =====CREATE CONFIRMATION ACTIONS=======

function loginStart() {
  return {
    type: actionTypes.LOGIN_START,
  };
}

function loginFail(error) {
  return {
    type: actionTypes.LOGIN_FAIL,
    error,
  };
}

export function loginSuccess(user) {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    user,
    error: '',
  };
}

export function loginData(name, value) {
  return { type: name, value };
}

export const doLogout = () => (dispatch) => {
  dispatch({
    type: actionTypes.LOG_OUT,
  });
  localStorage.removeItem('token');
  localStorage.removeItem('confirmedAccount');
  localStorage.removeItem('expirationDate');
  // window.location.replace('/login');
};

export function checkAuthTimeout(expirationTime) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(doLogout());
    }, expirationTime * 1000);
  };
}

// @desc    Login.

export function doLogin(email, password, showSwal = false) {
  return (dispatch) => {
    dispatch(loginStart());
    APIClient.post('/authentication/login', {
      email,
      password,
    })
      .then((res) => {
        if (res.data.success) {
          const { token, user } = res.data.data;
          const { exp, role, updateFinished, id } = jwt.decode(token);
          const expiresIn = Math.abs(Date.now() - exp * 1000);
          const expirationDate = new Date(Date.now());
          if (user.confirmedAccount) {
            localStorage.setItem('confirmedAccount', true);
          }
          dispatch(loginSuccess(user));
          dispatch({ type: 'TOKEN', token });
          dispatch(checkAuthTimeout(expiresIn));
          localStorage.setItem('token', token);
          localStorage.setItem('expirationDate', expirationDate);
          if (showSwal) {
            const options = {
              title: `Email sent successfully!`,
              text: `A verification email was sent to you, please check your email for further instructions!`,
              icon: 'success',
              buttons: {
                cancel: {
                  text: 'OK',
                  value: true,
                  visible: true,
                  className: 'bg-primary',
                  closeModal: true,
                },
              },
            };
            swal(options).then((p) => {});
          }

          if (user.confirmedAccount) {
            if (updateFinished) {
              if (role && role === 1) window.location.replace('/opportunities');
              else if (role && role === 2) window.location.replace('/organisationProfile');
              else if (role && role === 3) window.location.replace('/volunteerProfile');
              else window.location.replace('/opportunities');
            } else {
              if (role && role === 1) window.location.replace('/opportunities');
              else if (role && role === 2) window.location.replace(`/organisationEdit/${id}`);
              else if (role && role === 3) window.location.replace(`/volunteerEdit/${id}`);
              else window.location.replace('/opportunities');
            }
          }
        } else {
          dispatch(loginFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(loginFail(error.response.data.error));
        } else {
          dispatch(loginFail('Unable to comunicate with the server.'));
        }
      });
  };
}

export function cleanLoginError() {
  return {
    type: actionTypes.CLEAN_LOGIN_ERROR,
  };
}

export const clearData = () => {
  return {
    type: actionTypes.CLEAR,
  };
};

export function checkAuthState() {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(doLogout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate < new Date()) {
        const expiresIn = Date.now() - expirationDate.getTime();
        dispatch(loginSuccess(token));
        dispatch(checkAuthTimeout(expiresIn));
      } else {
        dispatch(doLogout());
      }
    }
  };
}
