import APIClient from '../../services/ApiService';
import * as actionTypes from '../types/actionTypes';
import jwt from 'jsonwebtoken';
import { doLogin, loginSuccess } from './actions';
// ? =====CREATE CONFIRMATION ACTIONS=======

function signupStartOrg() {
  return {
    type: actionTypes.SIGNUP_ORG_START,
  };
}

function signupFailOrg(error) {
  return {
    type: actionTypes.SIGNUP_ORG_FAIL,
    error,
  };
}

function signupSuccessOrg() {
  return {
    type: actionTypes.SIGNUP_ORG_SUCCESS,
    user,
  };
}

export function signupDataOrg(name, value) {
  return { type: name, value };
}
/// signup Volunteer
function signupStartVol() {
  return {
    type: actionTypes.SIGNUP_VOL_START,
  };
}

function signupFailVol(error) {
  return {
    type: actionTypes.SIGNUP_VOL_FAIL,
    error,
  };
}

function signupSuccessVol(user) {
  return {
    type: actionTypes.SIGNUP_VOL_SUCCESS,
    user,
    error: '',
  };
}

export function signupDataVol(name, value) {
  return { type: name, value };
}

/// signup confirm
function confirmStart() {
  return {
    type: actionTypes.CONFIRM_START,
  };
}

function confirmFail(error) {
  return {
    type: actionTypes.CONFIRM_FAIL,
    error,
  };
}

function confirmSuccess(responseData) {
  return {
    type: actionTypes.CONFIRM_SUCCESS,
    responseData,
  };
}

/// signup resend code
function codeStart() {
  return {
    type: actionTypes.CODE_START,
  };
}

function codeFail(payload) {
  return {
    type: actionTypes.CODE_FAIL,
    payload,
  };
}

function codeSuccess(payload) {
  return {
    type: actionTypes.CODE_SUCCESS,
    payload,
  };
}

export function cleanResendMessage() {
  return {
    type: actionTypes.CLEAN_RESEND_MESSAGE,
  };
}

export function cleanSignUpVolError() {
  return {
    type: actionTypes.CLEAN_SIGNUP_VOL_ERROR,
  };
}

export function cleanSignUpOrgError() {
  return {
    type: actionTypes.CLEAN_SIGNUP_ORG_ERROR,
  };
}

export function cleanRecoveryEmail() {
  return {
    type: actionTypes.CLEAN_RECOVERY_EMAIL,
  };
}

export function cleanResetPassword() {
  return {
    type: actionTypes.CLEAN_RESET_PASSWORD,
  };
}

export function sendRecoveryEmailSuccess(payload) {
  return {
    type: actionTypes.SEND_RECOVERY_EMAIL_SUCCESS,
    payload,
  };
}

export function sendRecoveryEmailFailed(payload) {
  return {
    type: actionTypes.SEND_RECOVERY_EMAIL_FAILED,
    payload,
  };
}

export function resetPasswordFailed(payload) {
  return {
    type: actionTypes.RESET_PASSWORD_FAILED,
    payload,
  };
}

// @desc    signup.

export function doSignupOrg(email, password, name, municipality, emailLanguage) {
  return (dispatch) => {
    dispatch(signupStartOrg());
    APIClient.post('/authentication/signup/organisation', {
      email,
      password,
      name,
      municipality,
      emailLanguage,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(doLogin(email, password, true));
        } else {
          dispatch(signupFailOrg(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(signupFailOrg(error.response.data.error));
        } else {
          dispatch(signupFailOrg('Unable to comunicate with the server.'));
        }
      });
  };
}

export function doSignupVol(
  email,
  password,
  firstName,
  lastName,
  gender,
  dateOfBirth,
  municipality,
  emailLanguage
) {
  return (dispatch) => {
    dispatch(signupStartVol());
    APIClient.post('/authentication/signup/volunteer', {
      email,
      password,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      municipality,
      emailLanguage,
    })
      .then((res) => {
        if (res.data.success) {
          dispatch(doLogin(email, password, true));
        } else {
          dispatch(signupFailVol(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(signupFailVol(error.response.data.error));
        } else {
          dispatch(signupFailVol('Unable to comunicate with the server.'));
        }
      });
  };
}

export function getConfirmation({ code, email }) {
  return (dispatch) => {
    dispatch(confirmStart());
    APIClient.post('/authentication/confirm', { code, email })
      .then((res) => {
        if (res.data.success && res.data.data) {
          dispatch(confirmSuccess(res.data));
          dispatch(loginSuccess(res.data.data.user));
        } else {
          dispatch(confirmFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          dispatch(confirmFail(error.response.data.message));
        } else {
          dispatch(confirmFail('Unable to comunicate with the server.'));
        }
      });
  };
}

export function resendCode(addToast, emailLanguage) {
  return (dispatch) => {
    dispatch(codeStart());
    APIClient.post('/authentication/resend', { emailLanguage })
      .then((res) => {
        if (res.data.success) {
          addToast('Code sent successfully to your email!', {
            appearance: 'success',
            autoDismiss: true,
            transitionDuration: 10,
          });
          // dispatch(codeSuccess({message:'Code sent!'}));
        } else {
          addToast('Failed to send code!', {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
          // dispatch(codeFail({message:res.data.message || 'Failed to send message'}));
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.error) {
          addToast(error.response.data.error, {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
        } else {
          addToast('Failed to send code!', {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
        }
      });
  };
}

export function sendRecoveryEmail(payload) {
  return (dispatch) => {
    const { email, emailLanguage } = payload;
    APIClient.post(`/authentication/forgot`, {
      email: email,
      emailLanguage,
    })
      .then((result) => {
        if (result && result.data && result.data.success) {
          dispatch(sendRecoveryEmailSuccess(result.data));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.error) {
          dispatch(sendRecoveryEmailFailed(error.response.data));
        } else {
          dispatch(sendRecoveryEmailFailed({ success: false, error: 'Something went wrong!' }));
        }
      });
  };
}

export function resetPassword(payload) {
  return (dispatch) => {
    const { email, password, confirmPassword, resetToken } = payload;
    APIClient.put(`/authentication/reset/${resetToken}`, {
      email,
      password,
      confirmPassword,
    })
      .then((result) => {
        if (result && result.data && result.data.success) {
          const { token } = result.data.data;
          const { exp } = jwt.decode(token);
          const expiresIn = Math.abs(Date.now() - exp * 1000);
          const expirationDate = new Date(Date.now());
          dispatch({ type: 'TOKEN', token });
          dispatch(checkAuthTimeout(expiresIn));
          localStorage.setItem('token', token);
          localStorage.setItem('expirationDate', expirationDate);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.error) {
          dispatch(resetPasswordFailed(error.response.data));
        } else {
          dispatch(resetPasswordFailed({ success: false, error: 'Something went wrong' }));
        }
      });
  };
}
