import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

/** LIST SCREENS */
const OpportunitiesList = lazy(() =>
  import('./components/Lists/OpportunitiesList/OpportunitiesList')
);
const OrganisationsList = lazy(() =>
  import('./components/Lists/OrganisationsList/OrganisationsList')
);
const MunicipalitiesList = lazy(() =>
  import('./components/Lists/MunicipalitiesList/MunicipalitiesList')
);
const CausesList = lazy(() => import('./components/Lists/CausesList/CausesList'));
const RegionsList = lazy(() => import('./components/Lists/RegionsList/RegionsList'));
const SkillsList = lazy(() => import('./components/Lists/SkillsList/SkillsList'));
const VolunteersList = lazy(() => import('./components/Lists/VolunteersList/VolunteersList'));
const TopVolunteersList = lazy(() =>
  import('./components/Lists/TopVolunteersList/TopVolunteersList')
);
const MatchedVolunteersList = lazy(() =>
  import('./components/Lists/MatchedVolunteersList/MatchedVolunteersList')
);
const VolunteerOpportunitiesList = lazy(() =>
  import('./components/Lists/VolunteerOpportunitiesList/VolunteerOpportunitiesList')
);
const ApplicationsList = lazy(() => import('./components/Lists/ApplicationsList/ApplicationsList'));
const MentorsList = lazy(() => import('./components/Lists/MentorsList/MentorsList'));
const OrgVolunteerList = lazy(() =>
  import('./components/Lists/OrgVolunteersList/OrgVolunteerList')
);
/** LIST SCREENS */

/** DETAIL SCREENS */
const MunicipalityDetail = lazy(() =>
  import('./screens/Details/MunicipalityDetail/MunicipalityDetail')
);
const CauseDetail = lazy(() => import('./screens/Details/CauseDetail/CauseDetail'));
const SkillDetail = lazy(() => import('./screens/Details/SkillDetail/SkillDetail'));
const RegionDetail = lazy(() => import('./screens/Details/RegionDetail/RegionDetail'));
const VolunteerProfile = lazy(() => import('./screens/Details/VolunteerProfile/VolunteerProfile'));
const OpportunityDetail = lazy(() =>
  import('./screens/Details/OpportunityDetail/OpportunityDetail')
);
const OrganisationProfile = lazy(() =>
  import('./screens/Details/OrganisationProfile/OrganisationProfile')
);
const MentorDetail = lazy(() => import('./screens/Details/MentorDetail/MentorDetail'));
/** DETAIL SCREENS */

/** STATISTICS SCREEN */
const Stats = lazy(() => import('./screens/Stats/Stats'));
/** STATISTICS SCREEN */

/** CREATE SCREENS */
const OpportunityCreate = lazy(() =>
  import('./screens/Create/OpportunityCreate/OpportunityCreate')
);
const MentorCreate = lazy(() => import('./screens/Create/MentorCreate/MentorCreate'));
/** CREATE SCREENS */

/** EDIT SCREENS */
const MunicipalityEdit = lazy(() => import('./screens/Edits/MunicipalityEdit/MunicipalityEdit'));
const CauseEdit = lazy(() => import('./screens/Edits/CauseEdit/CauseEdit'));
const SkillEdit = lazy(() => import('./screens/Edits/SkillEdit/SkillEdit'));
const RegionEdit = lazy(() => import('./screens/Edits/RegionEdit/RegionEdit'));
const OpportunityEdit = lazy(() => import('./screens/Edits/OpportunityEdit/OpportunityEdit'));
const OrganisationEdit = lazy(() => import('./screens/Edits/OrganisationEdit/OrganisationEdit'));
const VolunteerEdit = lazy(() => import('./screens/Edits/VolunteerEdit/VolunteerEdit'));
const MentorEdit = lazy(() => import('./screens/Edits/MentorEdit/MentorEdit'));
/** EDIT SCREENS */

/** AUTHENTICATION SCREENS */
const HomePage = lazy(() => import('./screens/Authentication/HomePage/HomePage'));
const ConfirmPage = lazy(() => import('./screens/Authentication/ConfirmPage/ConfirmPage'));
const ForgotPasswordPage = lazy(() =>
  import('./screens/Authentication/ForgotPasswordPage/ForgotPasswordPage')
);
const ResetPasswordPage = lazy(() =>
  import('./screens/Authentication/ForgotPasswordPage/ResetPasswordPage')
);
const MentorPage = lazy(() => import('./screens/Authentication/MentorPage/MentorPage'));
const ResourcesPage = lazy(() => import('./screens/Authentication/ResourcesPage/ResourcesPage'));
const SearchOpportunitiesPage = lazy(() =>
  import('./screens/Authentication/SearchOpportunitiesPage/SearchOpportunitiesPage')
);

/** AUTHENTICATION SCREENS */

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname

const listofPages = [
  '/login',
  '/confirm',
  '/forgot',
  '/reset',
  '/register',
  '/publicmentors',
  '/resources',
  '/searchOpportunities',
];
// const listofPages = [];

const Routes = ({ location, token }) => {
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'
  const confirmedAccount = localStorage.getItem('confirmedAccount');
  const decodedToken = jwt.decode(token);

  const animationName = 'rag-fadeIn';

  if (listofPages.indexOf(location.pathname) > -1) {
    if (token == null || typeof token == 'undefined') {
      return (
        // Page Layout component wrapper
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path='/login' component={waitFor(HomePage)} />
              <Route path='/confirm' component={waitFor(ConfirmPage)} />
              <Route path='/forgot' component={waitFor(ForgotPasswordPage)} />
              <Route path='/reset' component={waitFor(ResetPasswordPage)} />
              <Route path='/publicmentors' component={waitFor(MentorPage)} />
              <Route path='/resources' component={waitFor(ResourcesPage)} />
              <Route path='/searchOpportunities' component={waitFor(SearchOpportunitiesPage)} />
              {/* <Redirect to='/login' /> */}
              {/* {token ? <Redirect to='/adminview' /> : <Redirect to='/login' />} */}
              {/* See full project for reference */}
            </Switch>
          </Suspense>
        </BasePage>
      );
    } else {
      return (
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path='/login' component={waitFor(HomePage)} />
              <Route path='/confirm' component={waitFor(ConfirmPage)} />
              <Route path='/forgot' component={waitFor(ForgotPasswordPage)} />
              <Route path='/reset' component={waitFor(ResetPasswordPage)} />
              <Route path='/publicmentors' component={waitFor(MentorPage)} />
              <Route path='/resources' component={waitFor(ResourcesPage)} />
              <Route path='/searchOpportunities' component={waitFor(SearchOpportunitiesPage)} />
              {/* <Redirect to='/login' /> */}
              {/* See full project for reference */}
            </Switch>
          </Suspense>
        </BasePage>
      );
    }
  } else {
    if (token == null || typeof token == 'undefined') {
      return (
        <Base>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames={animationName}
              exit={false}
            >
              <div>
                <Suspense fallback={<PageLoader />}>
                  <Switch location={location}>
                    <Route path='/login' component={waitFor(HomePage)} />
                    <Route path='/confirm' component={waitFor(ConfirmPage)} />
                    <Route path='/forgot' component={waitFor(ForgotPasswordPage)} />
                    <Route path='/reset' component={waitFor(ResetPasswordPage)} />
                    <Route path='/publicmentors' component={waitFor(MentorPage)} />
                    <Route path='/resources' component={waitFor(ResourcesPage)} />
                    <Route
                      path='/searchOpportunities'
                      component={waitFor(SearchOpportunitiesPage)}
                    />
                    {/* {token ? <Redirect to='/adminview' /> : <Redirect to='/login' />} */}
                    {/* See full project for reference */}
                  </Switch>
                </Suspense>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Base>
      );
    } else if (decodedToken.role === 1) {
      return (
        // Layout component wrapper
        // Use <BaseHorizontal> to change layout
        <Base>
          <TransitionGroup>
            <CSSTransition
              key={currentKey}
              timeout={timeout}
              classNames={animationName}
              exit={false}
            >
              <div>
                <Suspense fallback={<PageLoader />}>
                  <Switch location={location}>
                    <Route path='/stats' component={waitFor(Stats)} />
                    <Route path='/opportunities' component={waitFor(OpportunitiesList)} />
                    <Route path='/organisations' component={waitFor(OrganisationsList)} />
                    <Route path='/volunteers' component={waitFor(VolunteersList)} />
                    <Route path='/topvolunteers' component={waitFor(TopVolunteersList)} />
                    <Route path='/matchedvolunteers' component={waitFor(MatchedVolunteersList)} />
                    <Route path='/causes' component={waitFor(CausesList)} />
                    <Route path='/skills' component={waitFor(SkillsList)} />
                    <Route path='/municipalities' component={waitFor(MunicipalitiesList)} />
                    <Route path='/regions' component={waitFor(RegionsList)} />
                    <Route path='/allmentors' component={waitFor(MentorsList)} />
                    <Route path='/createMentor' component={waitFor(MentorCreate)} />
                    <Route path='/viewMunicipality/:id' component={waitFor(MunicipalityDetail)} />
                    <Route path='/viewCause/:id' component={waitFor(CauseDetail)} />
                    <Route path='/viewSkill/:id' component={waitFor(SkillDetail)} />
                    <Route path='/viewRegion/:id' component={waitFor(RegionDetail)} />
                    <Route path='/viewVolunteer/:id' component={waitFor(VolunteerProfile)} />
                    <Route path='/viewOpportunity/:id' component={waitFor(OpportunityDetail)} />
                    <Route path='/viewMentor/:id' component={waitFor(MentorDetail)} />
                    <Route
                      path='/organisationProfile/:id'
                      component={waitFor(OrganisationProfile)}
                    />
                    <Route path='/editMunicipality/:id' component={waitFor(MunicipalityEdit)} />
                    <Route path='/editCause/:id' component={waitFor(CauseEdit)} />
                    <Route path='/editSkill/:id' component={waitFor(SkillEdit)} />
                    <Route path='/editRegion/:id' component={waitFor(RegionEdit)} />
                    <Route path='/editOpportunity/:id' component={waitFor(OpportunityEdit)} />
                    <Route path='/editMentor/:id' component={waitFor(MentorEdit)} />
                    <Redirect to='/opportunities' />
                  </Switch>
                </Suspense>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </Base>
      );
    } else if (decodedToken.role === 2 && confirmedAccount) {
      if (decodedToken.updateFinished) {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path='/organisationProfile' component={waitFor(OrganisationProfile)} />
                      <Route path='/organisationEdit/:id' component={waitFor(OrganisationEdit)} />
                      <Route path='/editOpportunity/:id' component={waitFor(OpportunityEdit)} />
                      <Route path='/createOpportunity' component={waitFor(OpportunityCreate)} />
                      <Route path='/opportunities' component={waitFor(OpportunitiesList)} />
                      <Route path='/volunteers' component={waitFor(VolunteersList)} />
                      <Route path='/orgvolunteers' component={waitFor(OrgVolunteerList)} />
                      <Route path='/viewOpportunity/:id' component={waitFor(OpportunityDetail)} />
                      <Route path='/viewVolunteer/:id' component={waitFor(VolunteerProfile)} />
                      <Redirect to='/organisationProfile' />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      } else {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      {/* <Route path='/organisationProfile' component={waitFor(OrganisationProfile)} /> */}
                      <Route path='/organisationEdit/:id' component={waitFor(OrganisationEdit)} />
                      {/* <Route path='/editOpportunity/:id' component={waitFor(OpportunityEdit)} />
                      <Route path='/createOpportunity' component={waitFor(OpportunityCreate)} />
                      <Route path='/opportunities' component={waitFor(OpportunitiesList)} />
                      <Route path='/volunteers' component={waitFor(VolunteersList)} />
                      <Route path='/orgvolunteers' component={waitFor(OrgVolunteerList)} />
                      <Route path='/viewOpportunity/:id' component={waitFor(OpportunityDetail)} />
                      <Route path='/viewVolunteer/:id' component={waitFor(VolunteerProfile)} /> */}
                      <Redirect to={`/organisationEdit/${decodedToken.id}`} />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      }
    } else if (decodedToken.role === 3 && confirmedAccount) {
      if (decodedToken.updateFinished) {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path='/volunteerProfile' component={waitFor(VolunteerProfile)} />
                      <Route path='/volunteerEdit/:id' component={waitFor(VolunteerEdit)} />
                      <Route
                        path='/opportunities'
                        component={waitFor(VolunteerOpportunitiesList)}
                      />
                      <Route path='/applications' component={waitFor(ApplicationsList)} />
                      <Route path='/viewOpportunity/:id' component={waitFor(OpportunityDetail)} />
                      <Route
                        path='/organisationProfile/:id'
                        component={waitFor(OrganisationProfile)}
                      />
                      <Redirect to='/volunteerProfile' />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      } else {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      {/* <Route path='/volunteerProfile' component={waitFor(VolunteerProfile)} /> */}
                      <Route path='/volunteerEdit/:id' component={waitFor(VolunteerEdit)} />
                      {/* <Route
                        path='/opportunities'
                        component={waitFor(VolunteerOpportunitiesList)}
                      />
                      <Route path='/applications' component={waitFor(ApplicationsList)} />
                      <Route path='/viewOpportunity/:id' component={waitFor(OpportunityDetail)} /> */}
                      <Redirect to={`/volunteerEdit/${decodedToken.id}`} />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      }
    } else {
      return (
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path='/login' component={waitFor(HomePage)} />
              <Route path='/confirm' component={waitFor(ConfirmPage)} />

              <Route path='/forgot' component={waitFor(ForgotPasswordPage)} />
              <Route path='/reset' component={waitFor(ResetPasswordPage)} />
              <Route path='/publicmentors' component={waitFor(MentorPage)} />
              <Route path='/resources' component={waitFor(ResourcesPage)} />
              <Route path='/searchOpportunities' component={waitFor(SearchOpportunitiesPage)} />
              <Redirect to='/login' />
              {/* See full project for reference */}
            </Switch>
          </Suspense>
        </BasePage>
      );
    }
  }
};

function mapStateToProps(state) {
  return {
    token: state.token?.token !== '' ? state.token?.token : null,
  };
}

export default withRouter(connect(mapStateToProps)(Routes));
