import {
  GET_ALL_SKILLS_SUCCESS,
  GET_ALL_SKILLS_FAILED,
  GET_ONE_SKILL_SUCCESS,
  GET_ONE_SKILL_FAILED,
  UPDATE_SKILL_SUCCESS,
  UPDATE_SKILL_FAILED,
  UPDATE_SKILL_TRANSLATION_SUCCESS,
  UPDATE_SKILL_TRANSLATION_FAILED,
} from '../actions/actions';

const initialState = {
  skills: null,
  skill: null,
  error: null,
};

const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        skills: action.payload,
        error: null,
      };
    case GET_ALL_SKILLS_FAILED:
      return {
        ...state,
        skills: null,
        error: action.payload,
      };
    case GET_ONE_SKILL_SUCCESS:
      return {
        ...state,
        skill: action.payload,
        error: null,
      };
    case GET_ONE_SKILL_FAILED:
      return {
        ...state,
        skill: null,
        error: action.payload,
      };
    case UPDATE_SKILL_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_SKILL_FAILED:
      return { ...state, ...action.payload };
    case UPDATE_SKILL_TRANSLATION_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_SKILL_TRANSLATION_FAILED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default skillsReducer;
