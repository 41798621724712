import {
  UPDATE_MUNICIPALITY_SUCCESS,
  UPDATE_MUNICIPALITY_FAILED,
  UPDATE_MUNICIPALITY_TRANSLATION_SUCCESS,
  UPDATE_MUNICIPALITY_TRANSLATION_FAILED,
} from '../actions/actions';

import * as actionTypes from '../types/actionTypes';

const initialState = {
  municipalities: null,
  error: null,
  loading: false,
  municipality: null,
};

const municipalitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    // @desc    Get all MUNICIPALITIES.
    
    case actionTypes.GET_MUNICIPALITIES_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_MUNICIPALITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        municipalities: action.responseData,
      };

    case actionTypes.GET_MUNICIPALITIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    // @desc    Get one MUNICIPALITIES.
    case actionTypes.GET_MUNICIPALITY_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actionTypes.GET_MUNICIPALITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        municipality: action.responseData,
      };

    case actionTypes.GET_MUNICIPALITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UPDATE_MUNICIPALITY_SUCCESS:
      return action.payload;
    case UPDATE_MUNICIPALITY_FAILED:
      return action.payload;
    case UPDATE_MUNICIPALITY_TRANSLATION_SUCCESS:
      return action.payload;
    case UPDATE_MUNICIPALITY_TRANSLATION_FAILED:
      return action.payload;
    default:
      return state;
  }
};

export default municipalitiesReducer;
