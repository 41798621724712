/* eslint-disable vars-on-top */
import { TOKEN, DELETE_TOKEN } from "../types/actionTypes";

const initialData = {
  token: null,
};

const tokenReducers = (state = initialData, action) => {
  switch (action.type) {
    case TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case DELETE_TOKEN:
      return {
        token: null,
      };
    default:
      return state;
  }
};

export default tokenReducers;
