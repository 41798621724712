import APIClient from "../../services/ApiService";

export const GET_ALL_VOLS_SUCCESS = "GET_ALL_VOLS_SUCCESS";
export const GET_ALL_VOLS_FAILED = "GET_ALL_VOLS_FAILED";
export const GET_ONE_VOL_SUCCESS = "GET_ONE_VOL_SUCCESS";
export const GET_ONE_VOL_FAILED = "GET_ONE_VOL_FAILED";
export const GET_BYHOURS_SUCCESS = "GET_BYHOURS_SUCCESS";
export const GET_BYHOURS_FAILED = "GET_BYHOURS_FAILED";
export const UPDATE_VOL_SUCCESS = "UPDATE_VOL_SUCCESS";
export const UPDATE_VOL_FAILED = "UPDATE_VOL_FAILED";
export const CLEAR_VOLUNTEER = "CLEAR_VOLUNTEER";

function getAllVolsSuccess(payload) {
  return { type: GET_ALL_VOLS_SUCCESS, payload };
}

function getAllVolsFailed(payload) {
  return { type: GET_ALL_VOLS_FAILED, payload };
}

function getOneVolSuccess(payload) {
  return { type: GET_ONE_VOL_SUCCESS, payload };
}

function getOneVolFailed(payload) {
  return { type: GET_ONE_VOL_FAILED, payload };
}

function getByHoursSuccess(payload) {
  return { type: GET_BYHOURS_SUCCESS, payload };
}

function getByHoursFailed(payload) {
  return { type: GET_BYHOURS_FAILED, payload };
}

function updateVolSuccess(payload) {
  return { type: UPDATE_VOL_SUCCESS, payload };
}

function updateVolFailed(payload) {
  return { type: UPDATE_VOL_FAILED, payload };
}

export function getAllVols(payload) {
  return (dispatch) => {
    const {
      page,
      limit,
      pagination,
      searchTerm,
      isActive,
      volHasBadge,
      volCertifications,
    } = payload;
    const query = {
      page,
      limit,
      pagination,
      volName: searchTerm,
      volSurname: searchTerm,
      isActive,
      volHasBadge,
      volCertifications,
    };

    return APIClient.get(`/volunteers`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { volunteers } = result.data.data;
          dispatch(getAllVolsSuccess({ volunteers, success: true }));
        } else {
          dispatch(
            getAllVolsFailed({
              volunteers: null,
              success: false,
              error: "Server Error!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getAllVolsFailed({
            volunteers: null,
            success: false,
            error: error.message || "Server Error!",
          })
        );
      });
  };
}

export function getOneVol(payload) {
  return (dispatch) => {
    const { volunteerId } = payload;
    return APIClient.get(`/volunteers/${volunteerId}`)
      .then((result) => {
        if (result.data && result.data.success) {
          const { volunteer, hours, applications, opportunities } =
            result.data.data;
          const volunteers = { volunteer, hours, applications, opportunities };
          dispatch(getOneVolSuccess({ volunteers, success: true }));
        } else {
          dispatch(
            getOneVolFailed({
              volunteers: {
                volunteer: null,
                hours: 0,
                applications: 0,
                opportunities: [],
              },
              success: false,
              error: "Server Error!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getOneVolFailed({
            volunteers: {
              volunteer: null,
              hours: 0,
              applications: 0,
              opportunities: [],
            },
            success: false,
            error: error.message || "Server Error!",
          })
        );
      });
  };
}

export function clearVolunteer() {
  return { type: CLEAR_VOLUNTEER };
}

export function clearGetByHours() {
  return getByHoursSuccess({ success: false, volunteers: null, error: null });
}

export function getByHours(payload) {
  return (dispatch) => {
    const { page, limit, pagination, term, startDate, endDate } = payload;
    const query = { page, limit, pagination, term, startDate, endDate };
    return APIClient.get(`/volunteers/all/byhours`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { volunteers } = result.data.data;
          dispatch(
            getByHoursSuccess({ success: true, volunteers, error: null })
          );
        } else {
          dispatch(
            getByHoursFailed({
              success: false,
              volunteers: null,
              error: "Internal Server Error!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getByHoursFailed({
            success: false,
            volunteers: null,
            error: error.message || "Server Error!",
          })
        );
      });
  };
}

export function clearUpdateVol() {
  return updateVolSuccess({
    updatedVolunteer: null,
    success: false,
    error: null,
  });
}

export function updateVol(payload) {
  return (dispatch) => {
    const { volunteerId } = payload;
    let tempPayload = { ...payload, volunteerId: undefined };
    return APIClient.put(`/volunteers/${volunteerId}`, tempPayload)
      .then((result) => {
        if (result.data && result.data.success) {
          const { volunteer, token } = result.data.data;

          dispatch({ type: "TOKEN", token });
          localStorage.setItem("token", token);

          dispatch(
            updateVolSuccess({
              updatedVolunteer: { volunteer, success: true },
              success: true,
              error: null,
            })
          );
        } else {
          dispatch(
            updateVolFailed({
              updatedVolunteer: { volunteer: null, sucess: false },
              success: false,
              error: "Server Error!",
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateVolFailed({
            updatedVolunteer: { volunteer: null, sucess: false },
            success: false,
            error: error.message || "Server Error!",
          })
        );
      });
  };
}
