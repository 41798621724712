import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Col,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import {
  doLogout,
  changeToken,
  clearOrganisation,
  clearVolunteer,
} from '../../store/actions/actions';

import ToggleFullscreen from '../Common/ToggleFullscreen';
import HeaderRun from './Header.run';

import logo from '../../assets/logo_front.png';
import enLogo from '../../assets/united.png';
import sqLogo from '../../assets/albania.png';
import srLogo from '../../assets/serbia.png';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
  const [languageOpen, setLanguageOpen] = useState(false);
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const languageLogo = language === 'en' ? enLogo : language === 'sq' ? sqLogo : srLogo;
  const { t, i18n } = useTranslation([]);
  const history = useHistory();

  useEffect(() => {
    HeaderRun();
  }, []);

  const toggleUserblock = (e) => {
    e.preventDefault();
    props.actions.toggleSetting('showUserBlock');
  };

  const toggleOffsidebar = (e) => {
    e.preventDefault();
    props.actions.toggleSetting('offsidebarOpen');
  };

  const toggleCollapsed = (e) => {
    e.preventDefault();
    props.actions.toggleSetting('isCollapsed');
    resize();
  };

  const resize = () => {
    // all IE friendly dispatchEvent
    var evt = document.createEvent('UIEvents');
    evt.initUIEvent('resize', true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  };

  const toggleAside = (e) => {
    e.preventDefault();
    props.actions.toggleSetting('asideToggled');
  };

  const toggleLanguage = () => {
    setLanguageOpen(!languageOpen);
  };
  const handleLogOut = () => {
    dispatch(doLogout());
    dispatch(changeToken('DELETE_TOKEN'));
    dispatch(clearOrganisation());
    dispatch(clearVolunteer());
    history.push('/login');
    // this.props.history.push('/login');
  };

  const changeLang = (val) => {
    i18n.changeLanguage(val);
  };

  return (
    <header className='topnavbar-wrapper'>
      {/* START Top Navbar */}
      <nav className='navbar topnavbar'>
        {/* START navbar header */}
        <div className='navbar-header'>
          <a className='navbar-brand' href='#/'>
            <div className='brand-logo'>
              <img className='img-fluid' src={logo} width={40} height={40} alt='App Logo' />
            </div>
            <div className='brand-logo-collapsed'>
              <img className='img-fluid' src={logo} width={40} height={40} alt='App Logo' />
            </div>
          </a>
        </div>
        {/* END navbar header */}

        {/* START Left navbar */}
        <ul className='navbar-nav mr-auto flex-row'>
          <li className='nav-item'>
            {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
            <a
              href=''
              className='nav-link d-none d-md-block d-lg-block d-xl-block'
              onClick={toggleCollapsed}
            >
              <em className='fas fa-bars'></em>
            </a>
            {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
            <a href='' className='nav-link sidebar-toggle d-md-none' onClick={toggleAside}>
              <em className='fas fa-bars'></em>
            </a>
          </li>
          {/* START User avatar toggle */}
          {/* <li className='nav-item d-none d-md-block'>
            <a className='nav-link' onClick={toggleUserblock}>
              <em className='icon-user'></em>
            </a>
          </li> */}
          {/* END User avatar toggle */}
          {/* START lock screen */}
          <li className='nav-item d-none d-md-block'>
            <a
              title='Log Out'
              onClick={(e) => {
                e.preventDefault();
                handleLogOut();
              }}
              className='nav-link'
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <em className='icon-lock'></em>
            </a>
          </li>

          <li className='nav-item d-none d-md-block'>
            <a
              title='Home Screen'
              onClick={(e) => {
                history.push('/login');
              }}
              className='nav-link'
              style={{ cursor: 'pointer', textDecoration: 'none' }}
            >
              <em className='icon-home'></em>
            </a>
          </li>
          {/* END lock screen */}
        </ul>
        {/* END Left navbar */}
        {/* START Right Navbar */}

        <ul className='navbar-nav flex-row'>
          <Col>
            <Dropdown isOpen={languageOpen} toggle={toggleLanguage}>
              <DropdownToggle style={{ background: 'none', border: 'none' }}>
                <img src={languageLogo}></img>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    changeLang('en');
                    dispatch(actions.changeLanguage('en'));
                  }}
                >
                  <img src={enLogo}></img>
                  <span className='ms-2'>ENGLISH</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    changeLang('sq');
                    dispatch(actions.changeLanguage('sq'));
                  }}
                >
                  <img src={sqLogo}></img>
                  <span className='ms-2'>ALBANIAN</span>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    changeLang('sr');
                    dispatch(actions.changeLanguage('sr'));
                  }}
                >
                  <img src={srLogo}></img>
                  <span className='ms-2'>SERBIAN</span>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
        </ul>
        {/* END Right Navbar */}

        {/* START Search form */}
        {/* END Search form */}
      </nav>
      {/* END Top Navbar */}
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
};

const mapStateToProps = (state) => ({ settings: state.settings });
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
