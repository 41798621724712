import APIClient from '../../services/ApiService';

export const GET_ALL_OPPS_SUCCESS = 'GET_ALL_OPPS_SUCCESS';
export const GET_ALL_OPPS_FAILED = 'GET_ALL_OPPS_FAILED';
export const GET_ONE_OPP_SUCCESS = 'GET_ONE_OPP_SUCCESS';
export const GET_ONE_OPP_FAILED = 'GET_ONE_OPP_FAILED';
export const CREATE_OPP_SUCCESS = 'CREATE_OPP_SUCCESS';
export const CREATE_OPP_FAILED = 'CREATE_OPP_FAILED';
export const UPDATE_OPP_SUCCESS = 'UPDATE_OPP_SUCCESS';
export const UPDATE_OPP_FAILED = 'UPDATE_OPP_FAILED';
export const GET_APPLICATIONS_FOR_OPP_SUCCESS = 'GET_APPLICATIONS_FOR_OPP_SUCCESS';
export const GET_APPLICATIONS_FOR_OPP_FAILED = 'GET_APPLICATIONS_FOR_OPP_FAILED';
export const RESET_OPPORTUNITIES_DOCS = 'RESET_OPPORTUNITIES_DOCS';

export const DELETE_OPPORTUNITY_SUCCESS = 'DELETE_OPPORTUNITY_SUCCESS';
export const DELETE_OPPORTUNITY_FAILED = 'DELETE_OPPORTUNITY_FAILED';
export const CLEAR_OPPORTUNITIES = 'CLEAR_OPPORTUNITIES';

function getAllOppsSuccess(payload) {
  return { type: GET_ALL_OPPS_SUCCESS, payload };
}

function getAllOppsFailed(payload) {
  return { type: GET_ALL_OPPS_FAILED, payload };
}

function getOneOppSuccess(payload) {
  return { type: GET_ONE_OPP_SUCCESS, payload };
}

function getOneOppFailed(payload) {
  return { type: GET_ONE_OPP_FAILED, payload };
}

function createOppSuccess(payload) {
  return { type: CREATE_OPP_SUCCESS, payload };
}

function createOppFailed(payload) {
  return { type: CREATE_OPP_FAILED, payload };
}

function updateOppSuccess(payload) {
  return { type: UPDATE_OPP_SUCCESS, payload };
}

function updateOppFailed(payload) {
  return { type: UPDATE_OPP_FAILED, payload };
}

function getApplicationsForOppSuccess(payload) {
  return { type: GET_APPLICATIONS_FOR_OPP_SUCCESS, payload };
}

function getApplicationsForOppFailed(payload) {
  return { type: GET_APPLICATIONS_FOR_OPP_FAILED, payload };
}

function deleteOpportunitySuccess(payload) {
  return { type: DELETE_OPPORTUNITY_SUCCESS, payload };
}

function deleteOpportunityFailed(payload) {
  return { type: DELETE_OPPORTUNITY_FAILED, payload };
}
export function resetOpportunitiesDocs() {
  return {
    type: RESET_OPPORTUNITIES_DOCS,
  };
}

export function getAllOpps(payload) {
  return (dispatch) => {
    const {
      page,
      limit,
      pagination,
      organisation,
      causes,
      skills,
      regions,
      title,
      isActive,
      type,
      education,
      latestQuery,
    } = payload;
    const query = {
      title,
      page,
      limit,
      pagination,
      organisation,
      causes,
      skills,
      regions,
      isActive,
      type,
      education,
      latestQuery,
    };

    return APIClient.get(`/opportunities`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { opportunities } = result.data.data;
          dispatch(getAllOppsSuccess(opportunities));
        } else {
          dispatch(
            getAllOppsFailed({
              opportunities: [],
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getAllOppsFailed({
            opportunities: [],
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearApplicationsForOpp() {
  return getApplicationsForOppSuccess({
    oppAplications: null,
    success: false,
    error: null,
  });
}

export function getApplicationsForOpp(payload) {
  return (dispatch) => {
    const { opportunityId, page, limit, pagination, isActive } = payload;
    const query = {
      page,
      limit,
      pagination,
      isActive,
    };
    APIClient.get(`/opportunities/${opportunityId}/applications`, {
      params: query,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { applications } = result.data.data;
          dispatch(
            getApplicationsForOppSuccess({
              oppApplications: { applications, success: true },
              success: true,
              error: null,
            })
          );
        } else {
          dispatch(
            getApplicationsForOppFailed({
              oppApplications: { applications: null, success: false },
              success: false,
              error: null,
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getApplicationsForOppFailed({
            oppApplications: { applications: null, success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function getOneOpp(id) {
  return (dispatch) => {
    return APIClient.get(`/opportunities/${id}`)
      .then((result) => {
        if (result.data && result.data.success) {
          const { opportunity } = result.data.data;
          dispatch(getOneOppSuccess({ opportunity, success: true, error: null }));
        } else {
          dispatch(
            getOneOppFailed({
              opportunity: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getOneOppFailed({
            opportunity: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearSuccess() {
  return createOppSuccess({
    opportunity: { opportunity: null, success: false },
  });
}

export function clearUpdateSuccess() {
  return updateOppSuccess({
    updatedOpportunity: { opportunity: null, success: false },
  });
}

export function clearAllOpps() {
  return {
    type: CLEAR_OPPORTUNITIES,
  };
}

export function createOpp(payload) {
  return (dispatch) => {
    const { addToast, history } = payload;
    const body = { ...payload, addToast: undefined, history: undefined };
    return APIClient.post('/opportunities', body)
      .then((result) => {
        if (result.data && result.data.success) {
          const { opportunity } = result.data.data;

          addToast('Opportunity created successfully!', {
            appearance: 'success',
            autoDismiss: true,
            transitionDuration: 10,
          });

          dispatch(
            createOppSuccess({
              opportunity: { ...opportunity, success: true },
              success: true,
            })
          );

          history.push('/opportunities');
        } else {
          addToast('Failed to create opportunity!', {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
          dispatch(
            createOppFailed({
              opportunity: { success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        addToast('Failed to create opportunity!', {
          appearance: 'error',
          autoDismiss: true,
          transitionDuration: 10,
        });

        dispatch(
          createOppFailed({
            opportunity: { success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function updateOpp(payload) {
  return (dispatch) => {
    const { opportunityId } = payload;
    let tempPayload = { ...payload, opportunityId: undefined };
    return APIClient.put(`/opportunities/${opportunityId}`, tempPayload)
      .then((result) => {
        if (result.data && result.data.success) {
          const { opportunity } = result.data.data;
          dispatch(
            updateOppSuccess({
              updatedOpportunity: { opportunity, success: true },
              success: true,
            })
          );
        } else {
          dispatch(
            updateOppFailed({
              updatedOpportunity: { success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateOppFailed({
            updatedOpportunity: { success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function deleteOpp(payload) {
  return (dispatch) => {
    const { opportunityId } = payload;
    APIClient.delete(`/opportunities/${opportunityId}`)
      .then((result) => {})
      .catch((error) => {});
  };
}
