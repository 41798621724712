import APIClient from "../../services/ApiService";
import * as actionTypes from "../types/actionTypes";

function getUsersStart() {
  return {
    type: actionTypes.GET_USERS_START,
  };
}

function getUsersFail(error) {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error,
  };
}

function getUsersSuccess(responseData) {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    responseData,
  };
}

export function getUsersOrg(id) {
  return (dispatch) => {
    dispatch(getUsersStart());
    APIClient.get(`/organisations/${id}`)
      .then((res) => {
        if (res.data.success) {
          dispatch(getUsersSuccess(res.data.data.organisation));
        } else {
          dispatch(getUsersFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          dispatch(getUsersFail(error.response.data.message));
        } else {
          dispatch(getUsersFail("Unable to comunicate with the server."));
        }
      });
  };
}

export function getUsersVol(id) {
  return (dispatch) => {
    dispatch(getUsersStart());
    APIClient.get(`/volunteers/${id}`)
      .then((res) => {
        if (res.data.success) {
          dispatch(getUsersSuccess(res.data.data.volunteer));
        } else {
          dispatch(getUsersFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          dispatch(getUsersFail(error.response.data.message));
        } else {
          dispatch(getUsersFail("Unable to comunicate with the server."));
        }
      });
  };
}
