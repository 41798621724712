import * as actionTypes from '../types/actionTypes';

const initialData = {
  user: null,
  error: null,
  loading: false,
};

const INITIAL_STATE = {
  error: '',
};

const login = (state = initialData, action) => {
  switch (action.type) {
    //  @desc   Create new confirmation.
    case actionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        error: null,
      };
    case actionTypes.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //  =================================
    case actionTypes.CLEAR:
      return INITIAL_STATE;
    case actionTypes.LOGOUT:
      return initialData;
    case actionTypes.LOG_OUT:
      return {
        ...state,
        loading: false,
        error: null,
        user: null,
      };
    case actionTypes.CLEAN_LOGIN_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default login;
