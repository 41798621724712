import { UPDATE_VOLUNTEERS_RESOURCES } from '../actions/actions';

const initialState = {};

const resourcesVolunteersReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VOLUNTEERS_RESOURCES:
      return action.payload;
    default:
      return {
        title: 'What it means to be a volunteer?',
        color: '#FE9000',
        id: 'collapseOne',
        headingId: 'headingOne',
        description: null,
        secondPart: {
          title: 'What it means to be a volunteer?',
          firstParagraph: (
            <>
              <span
                style={{
                  paddingTop: 10,
                  fontStyle: 'italic',
                  color: '#FE9000',
                }}
              >
                Volunteering is an altruistic activity which is intended to
                promote the human quality of life. In return, volunteers will
                receive self-respect despite there being no financial gain
                involved. In addition, volunteering also promotes skills
                development, fun, and socialization.
              </span>
              <span style={{ paddingTop: 10 }}>
                Some of the benefits to being a volunteer include: increased
                problem solving skills, acquiring new experiences, networking
                and making new friends and improvement in community life. Trust,
                tolerance, solidarity and reciprocity among citizens within and
                between communities are improved as a result of volunteers.
              </span>
            </>
          ),
          secondParagraph: (
            <div>
              <p>
                Based on the Kosovo Law on Empowerment and Participation of
                Youth, voluntary work will enable young people to reach their
                potential and improve their professional skills as well as
                enhance their life experiences through voluntary practices. An
                individual may choose to be a volunteer and serve the community
                because it is his or her choice and not that he or she is
                obligated to do so. Majority of volunteers are part of
                non-profit organizations or are organized in smaller groups for
                the same cause of solving or helping community issues. Being a
                volunteer does not only help the society, but it also widens
                more people’s motivations to become volunteers as well. During
                their voluntary experiences, volunteers usually learn about
                communications, leadership and teamwork. As such, they get to
                engage with new people and widen their social circle along the
                way. A common thing for most volunteers is the excitement and
                goal to reach the result they aimed for from the very beginning.
                There are indeed many examples of how people come together to
                work for a common cause. For example, in Kosovo, there is a good
                example of "Let's do it Kosovo" campaign, which is a citizen
                initiative working to address environmental issues throughout
                Kosovo. Other good examples include Red Cross of Kosova blood
                donation program where volunteer donors help save more than one
                life with just one donation; and Mother Teresa Society working
                to eradicate poverty among the poorest and most vulnerable
                communities in Kosovo.
              </p>
            </div>
          ),
        },
      };
  }
};

export default resourcesVolunteersReducer;
