import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

const Footer = (props) => {
    const { t } = useTranslation([]);
    const year = new Date().getFullYear();
    return (
      <footer className='footer-container'>
        <span>&copy; {year} {t('Footer.footerText')}</span>
      </footer>
    );
}

export default Footer;
