const Menu = [
  {
    heading: 'Main Navigation',
    translate: 'sidebar.heading.HEADER',
    user_access: [1, 2, 3],
  },
  {
    name: 'Stats',
    translate: 'sidebar.nav.MENU',
    path: '/stats',
    user_access: [1],
  },
  {
    name: 'My Profile',
    translate: 'sidebar.nav.MENU',
    path: '/organisationProfile',
    user_access: [2],
  },
  {
    name: 'My Profile',
    translate: 'sidebar.nav.MENU',
    path: '/volunteerProfile',
    user_access: [3],
  },
  {
    name: 'Opportunities',
    translate: 'sidebar.nav.MENU',
    path: '/opportunities',
    user_access: [1, 2, 3],
  },
  {
    name: 'Applications ',
    translate: 'sidebar.nav.MENU',
    path: '/applications',
    user_access: [3],
  },
  {
    name: 'Organisations',
    translate: 'sidebar.nav.MENU',
    path: '/organisations',
    user_access: [1],
  },
  {
    name: ' All Volunteers',
    translate: 'sidebar.nav.MENU',
    path: '/volunteers',
    user_access: [1, 2],
  },
  {
    name: 'Engaged Volunteers',
    translate: 'sidebar.nav.MENU',
    path: '/orgvolunteers',
    user_access: [2],
  },
  {
    name: 'Engaged Volunteers',
    translate: 'sidebar.nav.MENU',
    path: '/matchedvolunteers',
    user_access: [1],
  },
  {
    name: 'Top Volunteers',
    translate: 'sidebar.nav.MENU',
    path: '/topvolunteers',
    user_access: [1],
  },
  {
    name: 'Causes',
    translate: 'sidebar.nav.MENU',
    path: '/causes',
    user_access: [1],
  },
  {
    name: 'Skills',
    translate: 'sidebar.nav.MENU',
    path: '/skills',
    user_access: [1],
  },
  {
    name: 'Municipalities',
    translate: 'sidebar.nav.MENU',
    path: '/municipalities',
    user_access: [1],
  },
  {
    name: 'Regions',
    translate: 'sidebar.nav.MENU',
    path: '/regions',
    user_access: [1],
  },
  {
    name: 'Mentors',
    translate: 'sidebar.nav.MENU',
    path: '/allmentors',
    user_access: [1],
  },
];

export default Menu;
