import APIClient from '../../services/ApiService';

export const GET_ALL_ORGS_SUCCESS = 'GET_ALL_ORGS_SUCCESS';
export const GET_ALL_ORGS_FAILED = 'GET_ALL_ORGS_FAILED';
export const GET_ALL_ORGS_START = 'GET_ALL_ORGS_START';
export const GET_ONE_ORG_SUCCESS = 'GET_ONE_ORG_SUCCESS';
export const GET_ONE_ORG_FAILED = 'GET_ONE_ORG_FAILED';
export const GET_ONE_ORG_START = 'GET_ONE_ORG_START';
export const GET_MATCHES_SUCCESS = 'GET_MATCHES_SUCCESS';
export const GET_MATCHES_FAILED = 'GET_MATCHES_FAILED';
export const UPDATE_ORG_SUCCESS = 'UPDATE_ORG_SUCCESS';
export const UPDATE_ORG_FAILED = 'UPDATE_ORG_FAILED';
export const UPDATE_ORG_START = 'UPDATE_ORG_START';
export const CLEAR_ORGANISATION = 'CLEAR_ORGANISATION'

function getAllOrgsSuccess(payload) {
  return { type: GET_ALL_ORGS_SUCCESS, payload };
}

function getAllOrgsFailed(payload) {
  return { type: GET_ALL_ORGS_FAILED, payload };
}

function getAllOrgStart() {
  return { type: GET_ALL_ORGS_START };
}

function getOneOrgSuccess(payload) {
  return { type: GET_ONE_ORG_SUCCESS, payload };
}

function getOneOrgFailed(payload) {
  return { type: GET_ONE_ORG_FAILED, payload };
}

function getOneOrgStart() {
  return { type: GET_ONE_ORG_START };
}

function getMatchesSuccess(payload) {
  return { type: GET_MATCHES_SUCCESS, payload };
}

function getMatchesFailed(payload) {
  return { type: GET_MATCHES_FAILED, payload };
}

function updateOrgSuccess(payload) {
  return { type: UPDATE_ORG_SUCCESS, payload };
}

function updateOrgFailed(payload) {
  return { type: UPDATE_ORG_FAILED, payload };
}

function updateOrgStart() {
  return { type: UPDATE_ORG_START };
}

export function clearOrganisation(){
  return {type:CLEAR_ORGANISATION}
}

export function getAllOrgs(payload) {
  return (dispatch) => {
    dispatch(getAllOrgStart());
    const { page, limit, pagination, searchTerm, isActive, municipalities } = payload;
    const query = {
      page,
      limit,
      pagination,
      orgName: searchTerm,
      isActive,
      municipalities,
    };
    return APIClient.get(`/organisations`, { params: query })
      .then((result) => {
        if (result.data && result.data.success) {
          const { organisations } = result.data.data;
          dispatch(getAllOrgsSuccess(organisations));
        } else {
          dispatch(
            getAllOrgsFailed()
          );
        }
      })
      .catch((error) => {
        if(error && error.response && error.response.data && error.response.data.error){
          dispatch(
            getAllOrgsFailed(error.response.data.error)
          );
        }else {
          dispatch(
            getAllOrgsFailed('Server error!')
          );
        }
        
      });
  };
}

export function getOneOrg(payload) {
  return (dispatch) => {
    dispatch(getOneOrgStart());
    const { organisationId } = payload;
    return APIClient.get(`/organisations/${organisationId}`)
      .then((result) => {
        if (result.data && result.data.success) {
          dispatch(
            getOneOrgSuccess(result.data.data)
          );
        } else {
          dispatch(
            getOneOrgFailed('Server Error!')
          );
        }
      })
      .catch((error) => {
        if(error && error.response && error.response.data && error.response.data.error){
          dispatch(
            getOneOrgFailed(error.response.data.error)
          );
        }else {
          dispatch(
            getOneOrgFailed('Server Error!')
          );
        }
        
      });
  };
}

export function clearMatches() {
  return getMatchesSuccess({ success: true, applications: null, error: null });
}

export function getMatches(payload) {
  return async (dispatch) => {
    try {
      const { organisationId, page, limit, pagination, isActive, volHasBadge, volCertifications } =
        payload;
      const query = { page, limit, pagination, isActive, volHasBadge, volCertifications };

      const result = await APIClient.get(`/organisations/${organisationId}/matches`, {
        params: query,
      });
      if (result.data && result.data.success) {
        const { applications } = result.data.data;
        dispatch(getMatchesSuccess({ success: true, applications, error: null }));
      } else {
        dispatch(
          getMatchesFailed({
            success: false,
            applications: null,
            error: error.message || 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getMatchesFailed({
          success: false,
          applications: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}

export function updateOrg(payload) {
  return (dispatch) => {
    dispatch(updateOrgStart());
    const { organisationId ,history,addToast} = payload;
    let tempPayload = { ...payload, organisationId: undefined ,history:undefined,addToast:undefined};
    return APIClient.put(`/organisations/${organisationId}`, tempPayload)
      .then((result) => {
        if (result.data && result.data.success) {
          const { organisation, token } = result.data.data;
              addToast('Organisation updated successfully', {
                appearance: 'success',
                autoDismiss: true,
                transitionDuration: 10,
              });
        
          dispatch({ type: 'TOKEN', token });
          localStorage.setItem('token', token);

          dispatch(
            updateOrgSuccess(organisation)
          );
          history.push('/organisationProfile');
        } else {
          addToast('Server Error', {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
          dispatch(
            updateOrgFailed('Server Error')
          );
        }
      })
      .catch((error) => {
        if(error && error.response && error.response.data && error.response.data.error){
          addToast(error.response.data.error , {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
          dispatch(
            updateOrgFailed(
              error.response.data.error 
            )
          );
        }else {
          addToast('Server Error', {
            appearance: 'error',
            autoDismiss: true,
            transitionDuration: 10,
          });
          dispatch(
            updateOrgFailed('Server Error!')
          );
        }
        
      });
  };
}
