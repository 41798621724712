import { combineReducers } from 'redux';

import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import languageReducer from './language.reducer.js';
import skillsReducer from './skills.reducer';
import causesReducer from './causes.reducer';
import regionsReducer from './regions.reducer';
import municipalitiesReducer from './municipalities.reducer';
import signupReducer from './signup.reducer';
import loginReducer from './login.reducer';
import tokenReducers from './token.reducer';
import usersReducers from './users.reducer';
import volunteersResources from './resources.volunteers.reducer';
import opportunitiesReducer from './opportunities.reducer';
import organisationsReducer from './organisations.reducer';
import volunteersReducer from './volunteers.reducers';
import applicationReducer from './applications.reducer';
import mentorsReducer from './mentors.reducer.js';

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  token: tokenReducers,
  language: languageReducer,
  skills: skillsReducer,
  causes: causesReducer,
  regions: regionsReducer,
  municipalities: municipalitiesReducer,
  signup: signupReducer,
  login: loginReducer,
  users: usersReducers,
  volunteersResources: volunteersResources,
  opportunities: opportunitiesReducer,
  organisations: organisationsReducer,
  volunteers: volunteersReducer,
  applications: applicationReducer,
  mentors: mentorsReducer,
});
