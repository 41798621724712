import APIClient from '../../services/ApiService';

export const UPDATE_REGION_SUCCESS = 'UPDATE_REGION_SUCCESS';
export const UPDATE_REGION_FAILED = 'UPDATE_REGION_FAILED';
export const UPDATE_REGION_TRANSLATION_SUCCESS =
  'UPDATE_REGION_TRANSLATION_SUCCESS';
export const UPDATE_REGION_TRANSLATION_FAILED =
  'UPDATE_REGION_TRANSLATION_FAILED';

export const GET_ALL_REGIONS_SUCCESS = 'GET_ALL_REGIONS_SUCCESS';
export const GET_ALL_REGIONS_FAILED = 'GET_ALL_REGIONS_FAILED';

function getAllRegionsSuccess(payload) {
  return {
    type: GET_ALL_REGIONS_SUCCESS,
    payload,
  };
}

function getAllRegionsFailed(payload) {
  return { type: GET_ALL_REGIONS_FAILED, payload };
}

function updateRegionSuccess(payload) {
  return { type: UPDATE_REGION_SUCCESS, payload };
}

function updateRegionFailed(payload) {
  return { type: UPDATE_REGION_FAILED, payload };
}

function updateRegionTranslationSuccess(payload) {
  return { type: UPDATE_REGION_TRANSLATION_SUCCESS, payload };
}

function updateRegionTranslationFailed(payload) {
  return { type: UPDATE_REGION_TRANSLATION_FAILED, payload };
}

export function updateRegion(payload) {
  return (dispatch) => {
    return APIClient.put(`/regions/${payload.regionId}`, {
      mainName: payload.mainName,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { region } = result.data.data;
          dispatch(updateRegionSuccess({ region, success: true }));
        } else {
          dispatch(
            updateRegionFailed({
              region: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateRegionFailed({
            region: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearRegion() {
  return updateRegionSuccess({ region: null, success: false });
}

export function updateRegionTranslation(payload) {
  return (dispatch) => {
    return APIClient.put(`/regions/${payload.regionId}/translation`, {
      language: payload.language,
      name: payload.name,
      abbreviation: payload.abbreviation,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { region } = result.data.data;
          dispatch(updateRegionTranslationSuccess({ region, success: true }));
        } else {
          dispatch(
            updateRegionTranslationFailed({
              region: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateRegionTranslationFailed({
            region: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function getAllRegions(payload) {
  return (dispatch) => {
    const { page, limit, pagination } = payload;
    APIClient.get(
      `/regions?page=${page || 1}&limit=${limit || 10}&pagination=${pagination}`
    )
      .then((result) => {
        if (result && result.data && result.data.success) {
          const { regions } = result.data.data;
          dispatch(getAllRegionsSuccess({ regions, success: true }));
        } else {
          dispatch(
            getAllRegionsFailed({
              regions: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          getAllRegionsFailed({
            regions: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}
