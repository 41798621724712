import * as actionTypes from '../types/actionTypes';

const initialData = {
  user: null,
  error: null,
  orgError: null,
  orgLoading: false,
  loading: false,
  confirm: null,
  emailMessage:null,
  resetMessage:null,
  resendMessage:null

};

const INITIAL_STATE = {
  error: '',
};

const signup = (state = initialData, action) => {
  switch (action.type) {
    //  @desc   Create new confirmation.
    case actionTypes.SIGNUP_ORG_START:
      return {
        ...state,
        orgLoading: true,
      };
    case actionTypes.SIGNUP_ORG_SUCCESS:
      return {
        ...state,
        user: action.user,
        orgLoading: false,
        orgError: null,
      };
    case actionTypes.SIGNUP_ORG_FAIL:
      return {
        ...state,
        orgLoading: false,
        orgError: action.error,
      };

    case actionTypes.SIGNUP_VOL_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SIGNUP_VOL_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false,
        error: null,
      };
    case actionTypes.SIGNUP_VOL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //  =================================
    case actionTypes.CONFIRM_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CONFIRM_SUCCESS:
      return {
        ...state,
        confirm: action.responseData,
        loading: false,
        error: null,
      };
    case actionTypes.CONFIRM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.CLEAR_CONFIRM:
      return {
        ...state,
        confirm: null,
      };
    case actionTypes.CLEAR:
      return INITIAL_STATE;
    case actionTypes.LOGOUT:
      return initialData;
    case actionTypes.LOG_OUT:
      return {
        ...state,
        loading: false,
        orgLoading: false,
        orgError: null,
        error: null,
        user: null,
      };
    case actionTypes.CLEAN_SIGNUP_VOL_ERROR:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case actionTypes.CLEAN_SIGNUP_ORG_ERROR:
      return {
        ...state,
        orgError: null,
        orgLoading: false,
      };
    case actionTypes.SEND_RECOVERY_EMAIL_SUCCESS:
      return {
        ...state,
        emailMessage:action.payload
      }
    case actionTypes.SEND_RECOVERY_EMAIL_FAILED:
      return {
        ...state,
        emailMessage:action.payload
      }
    case actionTypes.CLEAN_RECOVERY_EMAIL:
      return {
        emailMessage:null
      }
    case actionTypes.RESET_PASSWORD_FAILED:
      return {
        ...state,
        resetMessage:action.payload
      }
      case actionTypes.CLEAN_RESET_PASSWORD:
        return {
          resetMessage:null
        }
      case actionTypes.CODE_SUCCESS:
        return {
          ...state,
          resendMessage:{
            success:true,
            ...action.payload
          }
        }
      case actionTypes.CODE_FAIL:
        return {
          ...state,
          resendMessage:{
            success:false,
            ...action.payload
          }
        }
        case actionTypes.CLEAN_RESEND_MESSAGE:
          return {
            resendMessage:{
              success:null,
              message:null
            }
          }
    default:
      return state;
  }
};

export default signup;
