import {
  UPDATE_REGION_SUCCESS,
  UPDATE_REGION_FAILED,
  UPDATE_REGION_TRANSLATION_SUCCESS,
  UPDATE_REGION_TRANSLATION_FAILED,
  GET_ALL_REGIONS_FAILED,
  GET_ALL_REGIONS_SUCCESS,
} from '../actions/actions';

const initialState = {
  regions: null,
  region: null,
  error: null,
};

const regionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_REGIONS_SUCCESS:
      return {
        ...state,
        regions: action.payload,
        error: null,
      };
    case GET_ALL_REGIONS_FAILED:
      return {
        ...state,
        regions: null,
        error: action.payload,
      };

    case UPDATE_REGION_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_REGION_FAILED:
      return { ...state, ...action.payload };
    case UPDATE_REGION_TRANSLATION_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_REGION_TRANSLATION_FAILED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default regionsReducer;
