import {
  GET_ALL_VOLS_SUCCESS,
  GET_ALL_VOLS_FAILED,
  GET_ONE_VOL_SUCCESS,
  GET_ONE_VOL_FAILED,
  GET_BYHOURS_SUCCESS,
  GET_BYHOURS_FAILED,
  UPDATE_VOL_SUCCESS,
  UPDATE_VOL_FAILED,
  CLEAR_VOLUNTEER,
} from '../actions/actions';

const initialState = {
  volunteers: null,
  volunteer: null,
  getByHourVols: null,
};

const volunteersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_VOLS_SUCCESS:
      return {
        ...state,
        volunteers: action.payload,
      };
    case GET_ALL_VOLS_FAILED:
      return {
        ...state,
        volunteers: action.payload,
      };
    case GET_ONE_VOL_SUCCESS:
      return {
        ...state,
        volunteer: action.payload,
      };
    case GET_ONE_VOL_FAILED:
      return {
        ...state,
        volunteer: action.payload,
      };
    case GET_BYHOURS_SUCCESS:
      return { ...state, getByHourVols: { ...action.payload } };
    case GET_BYHOURS_FAILED:
      return { ...state, getByHourVols: { ...action.payload } };
    case UPDATE_VOL_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_VOL_FAILED:
      return { ...state, ...action.payload };
    case CLEAR_VOLUNTEER:
      return initialState;
    default:
      return state;
  }
};

export default volunteersReducer;
