import APIClient from '../../services/ApiService';
import * as actionTypes from '../types/actionTypes';

export const UPDATE_MUNICIPALITY_SUCCESS = 'UPDATE_MUNICIPALITY_SUCCESS';
export const UPDATE_MUNICIPALITY_FAILED = 'UPDATE_MUNICIPALITY_FAILED';
export const UPDATE_MUNICIPALITY_TRANSLATION_SUCCESS = 'UPDATE_MUNICIPALITY_TRANSLATION_SUCCESS';
export const UPDATE_MUNICIPALITY_TRANSLATION_FAILED = 'UPDATE_MUNICIPALITY_TRANSLATION_FAILED';

function updateMunicipalitySuccess(payload) {
  return { type: UPDATE_MUNICIPALITY_SUCCESS, payload };
}

function updateMunicipalityFailed(payload) {
  return { type: UPDATE_MUNICIPALITY_FAILED, payload };
}

function updateMunicipalityTranslationSuccess(payload) {
  return { type: UPDATE_MUNICIPALITY_TRANSLATION_SUCCESS, payload };
}

function updateMunicipalityTranslationFailed(payload) {
  return { type: UPDATE_MUNICIPALITY_TRANSLATION_FAILED, payload };
}
// ? =====GET ALL Municipalities ACTIONS=====

function getMunicipalitiesStart() {
  return {
    type: actionTypes.GET_MUNICIPALITIES_START,
  };
}

function getMunicipalitiesFail(error) {
  return {
    type: actionTypes.GET_MUNICIPALITIES_FAIL,
    error,
  };
}

function getMunicipalitiesSuccess(responseData) {
  return {
    type: actionTypes.GET_MUNICIPALITIES_SUCCESS,
    responseData,
  };
}

// ? =====GET One Municipalities ACTIONS=====

function getMunicipalityStart() {
  return {
    type: actionTypes.GET_MUNICIPALITY_START,
  };
}

function getMunicipalityFail(error) {
  return {
    type: actionTypes.GET_MUNICIPALITY_FAIL,
    error,
  };
}

function getMunicipalitySuccess(responseData) {
  return {
    type: actionTypes.GET_MUNICIPALITY_SUCCESS,
    responseData,
  };
}

export function updateMunicipality(payload) {
  return (dispatch) => {
    return APIClient.put(`/municipalities/${payload.municipalityId}`, {
      mainName: payload.mainName,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { municipality } = result.data.data;
          dispatch(updateMunicipalitySuccess({ municipality, success: true }));
        } else {
          dispatch(
            updateMunicipalityFailed({
              municipality: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateMunicipalityFailed({
            municipality: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearMunicipality() {
  return updateMunicipalitySuccess({ municipality: null, success: false });
}

export function updateMunicipalityTranslation(payload) {
  return (dispatch) => {
    return APIClient.put(`/municipalities/${payload.municipalityId}/translation`, {
      language: payload.language,
      name: payload.name,
      abbreviation: payload.abbreviation,
    })
      .then((result) => {
        if (result.data && result.data.success) {
          const { municipality } = result.data.data;
          dispatch(
            updateMunicipalityTranslationSuccess({
              municipality,
              success: true,
            })
          );
        } else {
          dispatch(
            updateMunicipalityTranslationFailed({
              municipality: null,
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateMunicipalityTranslationFailed({
            municipality: null,
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function getMunicipalities(options) {
  return (dispatch) => {
    dispatch(getMunicipalitiesStart());
    const query = {
      page: options.page,
      limit: options.limit,
      pagination: options.pagination,
    };
    APIClient.get(`/municipalities`, { params: query })
      .then((res) => {
        if (res.data.success) {
          dispatch(getMunicipalitiesSuccess(res.data.data.municipalities));
        } else {
          dispatch(getMunicipalitiesFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          dispatch(getMunicipalitiesFail(error.response.data.message));
        } else {
          dispatch(getMunicipalitiesFail('Unable to comunicate with the server.'));
        }
      });
  };
}

export function getMunicipality(id) {
  return (dispatch) => {
    dispatch(getMunicipalityStart());
    APIClient.get(`/municipalities/${id}`)
      .then((res) => {
        if (res.data.success) {
          dispatch(getMunicipalitySuccess(res.data.data.municipality));
        } else {
          dispatch(getMunicipalityFail(res.data.message));
        }
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          dispatch(getMunicipalityFail(error.response.data.message));
        } else {
          dispatch(getMunicipalityFail('Unable to comunicate with the server.'));
        }
      });
  };
}
