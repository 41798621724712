export const LOGOUT = 'LOGOUT';
export const TOKEN = 'TOKEN';
export const DELETE_TOKEN = 'DELETE_TOKEN';
export const CLEAR = 'CLEAR';

export const SIGNUP_ORG_START = 'SIGNUP_ORG_START';
export const SIGNUP_ORG_FAIL = 'SIGNUP_ORG_FAIL';
export const SIGNUP_ORG_SUCCESS = 'SIGNUP_ORG_SUCCESS';

export const SIGNUP_VOL_START = 'SIGNUP_VOL_START';
export const SIGNUP_VOL_FAIL = 'SIGNUP_VOL_FAIL';
export const SIGNUP_VOL_SUCCESS = 'SIGNUP_VOL_SUCCESS';

export const CONFIRM_START = 'CONFIRM_START';
export const CONFIRM_FAIL = 'CONFIRM_FAIL';
export const CONFIRM_SUCCESS = 'CONFIRM_SUCCESS';
export const CLEAR_CONFIRM = 'CLEAR_CONFIRM';

export const CODE_START = 'CODE_START';
export const CODE_FAIL = 'CODE_FAIL';
export const CODE_SUCCESS = 'CODE_SUCCESS';

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOG_OUT = 'LOG_OUT';

export const GET_MUNICIPALITIES_START = 'GET_MUNICIPALITIES_START';
export const GET_MUNICIPALITIES_SUCCESS = 'GET_MUNICIPALITIES_SUCCESS';
export const GET_MUNICIPALITIES_FAIL = 'GET_MUNICIPALITIES_FAIL';

export const GET_MUNICIPALITY_START = 'GET_MUNICIPALITY_START';
export const GET_MUNICIPALITY_SUCCESS = 'GET_MUNICIPALITY_SUCCESS';
export const GET_MUNICIPALITY_FAIL = 'GET_MUNICIPALITY_FAIL';

export const GET_ALL_OPPS_SUCCESS = 'GET_ALL_OPPS_SUCCESS';

export const GET_USERS_START = 'GET_USERS_START';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const CLEAN_LOGIN_ERROR = 'CLEAN_LOGIN_ERROR';

export const CLEAN_SIGNUP_VOL_ERROR = 'CLEAN_SIGNUP_VOL_ERROR';
export const CLEAN_SIGNUP_ORG_ERROR = 'CLEAN_SIGNUP_ORG_ERROR';

export const SEND_RECOVERY_EMAIL_SUCCESS = 'SEND_RECOVERY_EMAIL_SUCCESS';
export const SEND_RECOVERY_EMAIL_FAILED = 'SEND_RECOVERY_EMAIL_SUCCESS';
export const CLEAN_RECOVERY_EMAIL = 'CLEAN_RECOVERY_EMAIL';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';
export const CLEAN_RESET_PASSWORD = 'CLEAN_RESET_PASSWORD';
export const CLEAN_RESEND_MESSAGE = 'CLEAN_RESEND_MESSAGE';