import {
  GET_ALL_ORGS_SUCCESS,
  GET_ALL_ORGS_FAILED,
  GET_ALL_ORGS_START,
  GET_ONE_ORG_SUCCESS,
  GET_ONE_ORG_FAILED,
  GET_ONE_ORG_START,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_FAILED,
  UPDATE_ORG_SUCCESS,
  UPDATE_ORG_FAILED,
  UPDATE_ORG_START,
  CLEAR_ORGANISATION
} from '../actions/actions';

const initialState = {
  organisations:null,
  organisation:null,
  success:null,
  loading:false,
  error:null,
  getAllMatches: null,
};

const organisationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_ORGS_START:
      return { 
        ...state,
        loading:true 
      };
    case GET_ALL_ORGS_SUCCESS:
      return { 
        ...state,
        organisations:{...action.payload,success:true},
        loading:false
       };
    case GET_ALL_ORGS_FAILED:
      return { 
        ...state,
        organisations:null,
        loading:false,
        error:action.payload
       };
    case GET_ONE_ORG_START:
      return { 
        ...state,
        loading:true,
       };
    case GET_ONE_ORG_SUCCESS:
      return { 
        ...state,
        organisation:{...action.payload,success:true},
        loading:false,
      };
    case GET_ONE_ORG_FAILED:
      return { 
        ...state,
        organisation:null,
        loading:false,
        error:action.payload
      };
    case UPDATE_ORG_SUCCESS:
      return { 
        ...state,
        organisation:{...action.payload,success:true},
        loading:false
      };
    case UPDATE_ORG_FAILED:
      return { 
        ...state,
        organisation:null,
        loading:false   ,
        error:action.payload
      };
    case UPDATE_ORG_START:
      return { 
        ...state,
         loading:true };
    case CLEAR_ORGANISATION:
      return initialState;
    case GET_MATCHES_SUCCESS:
      return { ...state, getAllMatches: { ...action.payload } };
    case GET_MATCHES_FAILED:
      return { ...state, getAllMatches: { ...action.payload } };
    default:
      return state;
  }
};

export default organisationsReducer;
