import APIClient from '../../services/ApiService';

export const GET_ALL_MENTORS_SUCCESS = 'GET_ALL_MENTORS_SUCCESS';
export const GET_ALL_MENTORS_FAILED = 'GET_ALL_MENTORS_FAILED';
export const GET_ONE_MENTOR_SUCCESS = 'GET_ONE_MENTOR_SUCCESS';
export const GET_ONE_MENTOR_FAILED = 'GET_ONE_MENTOR_FAILED';
export const CREATE_MENTOR_SUCCESS = 'CREATE_MENTOR_SUCCESS';
export const CREATE_MENTOR_FAILED = 'CREATE_MENTOR_FAILED';
export const UPDATE_MENTOR_SUCCESS = 'UPDATE_MENTOR_SUCCESS';
export const UPDATE_MENTOR_FAILED = 'UPDATE_MENTOR_FAILED';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_FAILED = 'UPLOAD_PHOTO_FAILED';
export const DELETE_MENTOR_SUCCESS = 'DELETE_MENTOR_SUCCESS';
export const DELETE_MENTOR_FAILED = 'DELETE_MENTOR_FAILED';

const getAllMentorsSuccess = (payload) => {
  return { type: GET_ALL_MENTORS_SUCCESS, payload };
};

const getAllMentorsFailed = (payload) => {
  return { type: GET_ALL_MENTORS_FAILED, payload };
};

const getOneMentorSuccess = (payload) => {
  return { type: GET_ONE_MENTOR_SUCCESS, payload };
};

const getOneMentorFailed = (payload) => {
  return { type: GET_ONE_MENTOR_FAILED, payload };
};

const createMentorSuccess = (payload) => {
  return { type: CREATE_MENTOR_SUCCESS, payload };
};

const createMentorFailed = (payload) => {
  return { type: CREATE_MENTOR_FAILED, payload };
};

const updateMentorSuccess = (payload) => {
  return { type: UPDATE_MENTOR_SUCCESS, payload };
};

const updateMentorFailed = (payload) => {
  return { type: UPDATE_MENTOR_FAILED, payload };
};

const uploadPhotoSuccess = (payload) => {
  return { type: UPLOAD_PHOTO_SUCCESS, payload };
};

const uploadPhotoFailed = (payload) => {
  return { type: UPLOAD_PHOTO_FAILED, payload };
};

const deleteMentorSuccess = (payload) => {
  return { type: DELETE_MENTOR_SUCCESS, payload };
};

const deleteMentorFailed = (payload) => {
  return { type: DELETE_MENTOR_FAILED, payload };
};

export function getAllMentors(payload) {
  return async (dispatch) => {
    try {
      const { page, limit, pagination } = payload;
      const query = { page, limit, pagination };

      const result = await APIClient.get('/mentors', { params: query });
      if (result.data && result.data.success) {
        const { mentors } = result.data.data;
        dispatch(getAllMentorsSuccess({ success: true, mentors, error: null }));
      } else {
        dispatch(
          getAllMentorsFailed({ success: false, mentors: null, error: 'Internal Server Error!' })
        );
      }
    } catch (error) {
      dispatch(
        getAllMentorsFailed({
          success: false,
          mentors: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}

export function getOneMentor(payload) {
  return async (dispatch) => {
    try {
      const { mentorId } = payload;
      const result = await APIClient.get(`/mentors/${mentorId}`);
      if (result.data && result.data.success) {
        const { mentor } = result.data.data;
        dispatch(getOneMentorSuccess({ success: true, mentor, error: null }));
      } else {
        dispatch(
          getOneMentorFailed({ success: false, mentor: null, error: 'Internal Server Error!' })
        );
      }
    } catch (error) {
      dispatch(
        getOneMentorFailed({
          success: false,
          mentor: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}

export function clearCreateMentor() {
  return createMentorSuccess({ success: false, mentor: null, error: null });
}

export function createMentor(payload) {
  return async (dispatch) => {
    try {
      const result = await APIClient.post('/mentors', payload);
      if (result.data && result.data.success) {
        const { mentor } = result.data.data;
        dispatch(createMentorSuccess({ success: true, mentor, error: null }));
      } else {
        dispatch(
          createMentorFailed({
            success: false,
            mentor: null,
            error: 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        createMentorFailed({
          success: false,
          mentor: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}

export function clearUpdateMentor() {
  return updateMentorSuccess({ success: false, mentor: null, error: null });
}

export function updateMentor(payload) {
  return async (dispatch) => {
    try {
      const { mentorId } = payload;
      const body = { ...payload, mentorId: undefined };

      const result = await APIClient.put(`/mentors/${mentorId}`, body);
      if (result.data && result.data.success) {
        const { mentor } = result.data.data;
        dispatch(updateMentorSuccess({ success: true, mentor, error: null }));
      } else {
        dispatch(
          updateMentorFailed({ success: false, mentor: null, error: 'Internal Server Error' })
        );
      }
    } catch (error) {
      dispatch(
        updateMentorFailed({
          success: false,
          mentor: null,
          error: error.message || 'Internal Server Error',
        })
      );
    }
  };
}

export function clearUploadPhoto() {
  return uploadPhotoSuccess({ success: false, mentor: null, error: null });
}

export function uploadPhoto(payload) {
  return async (dispatch) => {
    try {
      const { mentorId, photoData } = payload;

      const result = await APIClient.patch(`/mentors/${mentorId}/photo`, photoData);
      if (result.data && result.data.success) {
        const { mentor } = result.data.data;
        dispatch(uploadPhotoSuccess({ success: true, mentor, error: null }));
      } else {
        dispatch(
          uploadPhotoFailed({ success: false, mentor: null, error: 'Internal Server Error' })
        );
      }
    } catch (error) {
      dispatch(
        uploadPhotoFailed({
          success: false,
          mentor: null,
          error: error.message || 'Internal Server Error',
        })
      );
    }
  };
}

export function clearDeleteMentor() {
  return deleteMentorSuccess({ success: false, mentor: null, error: null });
}

export function deleteMentor(payload) {
  return async (dispatch) => {
    try {
      const { mentorId } = payload;
      const result = await APIClient.delete(`/mentors/${mentorId}`);
      if (result.data && result.data.success) {
        const { mentor } = result.data.data;
        dispatch(deleteMentorSuccess({ success: true, mentor, error: null }));
      } else {
        dispatch(
          deleteMentorFailed({
            success: false,
            mentor: null,
            error: 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        deleteMentorFailed({
          success: false,
          mentor: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}
