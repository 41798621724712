import {
  GET_ALL_MENTORS_SUCCESS,
  GET_ALL_MENTORS_FAILED,
  GET_ONE_MENTOR_SUCCESS,
  GET_ONE_MENTOR_FAILED,
  CREATE_MENTOR_SUCCESS,
  CREATE_MENTOR_FAILED,
  UPDATE_MENTOR_SUCCESS,
  UPDATE_MENTOR_FAILED,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_FAILED,
  DELETE_MENTOR_SUCCESS,
  DELETE_MENTOR_FAILED,
} from '../actions/actions';

const initialState = {
  getAll: null,
  getOne: null,
  create: null,
  updateOne: null,
  uploadPhoto: null,
  deleteOne: null,
};

const mentorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MENTORS_SUCCESS:
      return { ...state, getAll: { ...action.payload } };
    case GET_ALL_MENTORS_FAILED:
      return { ...state, getAll: { ...action.payload } };
    case GET_ONE_MENTOR_SUCCESS:
      return { ...state, getOne: { ...action.payload } };
    case GET_ONE_MENTOR_FAILED:
      return { ...state, getOne: { ...action.payload } };
    case CREATE_MENTOR_SUCCESS:
      return { ...state, create: { ...action.payload } };
    case CREATE_MENTOR_FAILED:
      return { ...state, create: { ...action.payload } };
    case UPDATE_MENTOR_SUCCESS:
      return { ...state, updateOne: { ...action.payload } };
    case UPDATE_MENTOR_FAILED:
      return { ...state, updateOne: { ...action.payload } };
    case UPLOAD_PHOTO_SUCCESS:
      return { ...state, uploadPhoto: { ...action.payload } };
    case UPLOAD_PHOTO_FAILED:
      return { ...state, uploadPhoto: { ...action.payload } };
    case DELETE_MENTOR_SUCCESS:
      return { ...state, deleteOne: { ...action.payload } };
    case DELETE_MENTOR_FAILED:
      return { ...state, deleteOne: { ...action.payload } };
    default:
      return state;
  }
};

export default mentorsReducer;
