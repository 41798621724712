import {
  GET_APPLICATIONS_FAILED,
  GET_APPLICATIONS_SUCCESS,
  CREATE_APPLICATION_SUCCESS,
  CREATE_APPLICATION_FAILED,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_APPLICATION_FAILED,
  FAVOURITE_APPLICATION_SUCCESS,
  FAVOURITE_APPLICATION_FAILED,
  UNFAVOURITE_APPLICATION_SUCCESS,
  UNFAVOURITE_APPLICATION_FAILED,
  RESET_APPLICATION_DOCS
} from '../actions/actions';

const initialState = {
  applications:null,
  applicationsDocs:[],
  success:null,
  error:null
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATIONS_SUCCESS:
      return { 
        ...state,
         applications:action.payload,
         applicationsDocs:state.applicationsDocs.concat(action.payload.applications.docs),
         success:true
        };
    case GET_APPLICATIONS_FAILED:
      return { 
        ...state,
        applications:null,
        applicationsDocs:[],
        success:false,
        error:action.payload  
      };
    case CREATE_APPLICATION_SUCCESS:
      return { ...state, ...action.payload };
    case CREATE_APPLICATION_FAILED:
      return { ...state, ...action.payload };
    case UPDATE_APPLICATION_SUCCESS:
      return { ...state, ...action.payload };
    case UPDATE_APPLICATION_FAILED:
      return { ...state, ...action.payload };
    case FAVOURITE_APPLICATION_SUCCESS:
      return { ...state, ...action.payload };
    case FAVOURITE_APPLICATION_FAILED:
      return { ...state, ...action.payload };
    case UNFAVOURITE_APPLICATION_SUCCESS:
      return { ...state, ...action.payload };
    case UNFAVOURITE_APPLICATION_FAILED:
      return { ...state, ...action.payload };
    case RESET_APPLICATION_DOCS:
      return {
        ...state,
        applicationsDocs:[]
      }
    default:
      return state;
  }
};

export default applicationReducer;
