import APIClient from '../../services/ApiService';
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS';
export const GET_APPLICATIONS_FAILED = 'GET_APPLICATIONS_FAILED';
export const CREATE_APPLICATION_SUCCESS = 'CREATE_APPLICATION_SUCCESS';
export const CREATE_APPLICATION_FAILED = 'CREATE_APPLICATION_FAILED';
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS';
export const UPDATE_APPLICATION_FAILED = 'UPDATE_APPLICATION_FAILED';
export const FAVOURITE_APPLICATION_SUCCESS = 'FAVOURITE_APPLICATION_SUCCESS';
export const FAVOURITE_APPLICATION_FAILED = 'FAVOURITE_APPLICATION_FAILED';
export const UNFAVOURITE_APPLICATION_SUCCESS = 'UNFAVOURITE_APPLICATION_SUCCESS';
export const UNFAVOURITE_APPLICATION_FAILED = 'UNFAVOURITE_APPLICATION_FAILED';
export const RESET_APPLICATION_DOCS = 'RESET_APPLICATION_DOCS';

function getApplicationsSuccess(payload) {
  return { type: GET_APPLICATIONS_SUCCESS, payload };
}

function getApplicationsFailed(payload) {
  return { type: GET_APPLICATIONS_FAILED, payload };
}

function createApplicationSuccess(payload) {
  return { type: CREATE_APPLICATION_SUCCESS, payload };
}

function createApplicationFailed(payload) {
  return { type: CREATE_APPLICATION_SUCCESS, payload };
}

function updateApplicationSuccess(payload) {
  return { type: UPDATE_APPLICATION_SUCCESS, payload };
}

function updateApplicationFailed(payload) {
  return { type: UPDATE_APPLICATION_FAILED, payload };
}

function favouriteApplicationSuccess(payload) {
  return { type: FAVOURITE_APPLICATION_SUCCESS, payload };
}

function favouriteApplicationFailed(payload) {
  return { type: FAVOURITE_APPLICATION_FAILED, payload };
}

function unfavouriteApplicationSuccess(payload) {
  return { type: UNFAVOURITE_APPLICATION_SUCCESS, payload };
}

function unfavouriteApplicationFailed(payload) {
  return { type: UNFAVOURITE_APPLICATION_FAILED, payload };
}

export function clearAllApplications() {
  return getApplicationsSuccess({
    success: false,
    applications: null,
    error: null,
  });
}

export function getApplications(payload) {
  return async (dispatch) => {
    try {
      const {
        page,
        limit,
        pagination,
        volunteer,
        accepted,
        certified,
        certifiedByMinistry,
        canceledByOrg,
        canceledByVol,
        isActive,
        opportunity,
        organisation,
      } = payload;
      const query = {
        page,
        limit,
        pagination,
        volunteer,
        accepted,
        certified,
        certifiedByMinistry,
        canceledByOrg,
        canceledByVol,
        isActive,
        opportunity,
        organisation,
      };
      const result = await APIClient.get(`/applications`, { params: query });

      if (result && result.data && result.data.success) {
        const { applications } = result.data.data;
        dispatch(
          getApplicationsSuccess({
            success: true,
            applications,
            error: null,
          })
        );
      } else {
        dispatch(
          getApplicationsFailed({
            success: false,
            applications: null,
            error: 'Internal Server Error!',
          })
        );
      }
    } catch (error) {
      dispatch(
        getApplicationsFailed({
          success: false,
          applications: null,
          error: error.message || 'Internal Server Error!',
        })
      );
    }
  };
}

export function clearNewApplication() {
  return createApplicationSuccess({ newApplication: null });
}

export function clearUpdateApplication() {
  return updateApplicationSuccess({ updatedApplication: null });
}

export function resetApplicationDocs() {
  return {
    type: RESET_APPLICATION_DOCS,
  };
}

export function updateApplication(payload) {
  return (dispatch) => {
    const { type, volunteer, applicationId, emailLanguage } = payload;
    APIClient.put(`/applications/${applicationId}`, { type, volunteer, emailLanguage })
      .then((result) => {
        if (result && result.data && result.data.success) {
          const { application } = result.data.data;
          dispatch(
            updateApplicationSuccess({
              updatedApplication: { application, success: true },
              success: true,
            })
          );
        } else {
          dispatch(
            updateApplicationFailed({
              updatedApplication: { application: null, success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          updateApplicationFailed({
            updatedApplication: { application: null, success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function clearFavouriteApplication() {
  return favouriteApplicationSuccess({
    favouriteApplication: null,
    success: true,
    error: null,
  });
}

export function favouriteApplication(payload) {
  return (dispatch) => {
    const { opportunityId, applicationId } = payload;
    APIClient.post(`/applications/${applicationId}/favourite`, {
      opportunityId,
    })
      .then((result) => {
        if (result && result.data && result.data.success) {
          dispatch(
            favouriteApplicationSuccess({
              favouriteApplication: { success: true },
              success: true,
              error: null,
            })
          );
        } else {
          dispatch(
            favouriteApplicationFailed({
              favouriteApplication: { success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        favouriteApplicationFailed({
          favouriteApplication: { success: false },
          success: false,
          error: error.message || 'Server Error!',
        });
      });
  };
}

export function clearUnfavouriteApplication() {
  return unfavouriteApplicationSuccess({
    unfavouriteApplication: null,
    success: true,
    error: null,
  });
}

export function unfavouriteApplication(payload) {
  return (dispatch) => {
    const { opportunityId, applicationId } = payload;
    APIClient.post(`/applications/${applicationId}/unfavourite`, {
      opportunityId,
    })
      .then((result) => {
        if (result && result.data && result.data.success) {
          dispatch(
            unfavouriteApplicationSuccess({
              unfavouriteApplication: { success: true },
              success: true,
              error: null,
            })
          );
        } else {
          dispatch(
            unfavouriteApplicationFailed({
              unfavouriteApplication: { success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          unfavouriteApplicationFailed({
            unfavouriteApplication: { success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}

export function createApplication(payload) {
  return (dispatch) => {
    const { opportunityId, emailLanguage } = payload;
    APIClient.post('/applications', { opportunity: opportunityId, emailLanguage })
      .then((result) => {
        if (result && result.data && result.data.success) {
          const { application } = result.data.data;
          dispatch(
            createApplicationSuccess({
              newApplication: { application, success: true },
              success: true,
            })
          );
        } else {
          dispatch(
            createApplicationFailed({
              newApplication: { application: null, success: false },
              success: false,
              error: 'Server Error!',
            })
          );
        }
      })
      .catch((error) => {
        dispatch(
          createApplicationFailed({
            newApplication: { application: null, success: false },
            success: false,
            error: error.message || 'Server Error!',
          })
        );
      });
  };
}
